import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';
import { AuthAdminGuard } from './_guards/authAdmin.guard';
import { VerifGuard } from './_guards/verif.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },

  {
    path: 'forgot-password',
    loadChildren: () => import('./login/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },

  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact.module').then( m => m.ContactPageModule)
  },

  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },

  {
    path: 'pricing',
    loadChildren: () => import('./pricing/pricing.module').then( m => m.PricingPageModule)
  },

  {
    path: 'my-subscription',
    canActivate: [AuthGuard, VerifGuard],
    loadChildren: () => import('./my-subscription/my-subscription.module').then( m => m.MySubscriptionPageModule)
  },

  {
    path: 'terms-of-service',
    loadChildren: () => import('./terms-of-service/termsofservice.module').then( m => m.TermsOfServicePageModule)
  },

  {
    path: 'cookie-policy',
    loadChildren: () => import('./cookie-policy/cookiepolicy.module').then( m => m.CookiePolicyPageModule)
  },

  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FAQPageModule)
  },

  {
    path: 'promote-listing',
    canActivate: [AuthGuard],
    loadChildren: () => import('./promote/listing/promoteListing.module').then( m => m.PromoteListingPageModule)
  },
  {
    path: 'promote-brand',
    canActivate: [AuthGuard],
    loadChildren: () => import('./promote/brand/promoteBrand.module').then( m => m.PromoteBrandPageModule)
  },

  {
    path: 'what-can-i-list-on-world-luxury-home',
    loadChildren: () => import('./help-you/help-you.module').then( m => m.HelpYouPageModule)
  },

  {
    path: 'our-mission',
    loadChildren: () => import('./our-mission/our-mission.module').then( m => m.OurMissionPageModule)
  },

  {
    path: 'why-choose-us',
    loadChildren: () => import('./choose-us/choose-us.module').then( m => m.ChooseUsPageModule)
  },

  {
    path: 'admin',
    canActivate: [AuthGuard, AuthAdminGuard],
    children: [
      {
        path: 'import-xml',
        loadChildren: () => import('./admin/import-xml/importXml.module').then( m => m.ImportXmlAdminPageModule)
      },
      {
        path: 'cities',
        loadChildren: () => import('./admin/cities/cities.module').then( m => m.AdminCitiesPageModule)
      },
      {
        path: '',
        loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
      }
    ]
  },

  {
    path: 'email-verification',
    loadChildren: () => import('./login/verification/verification.module').then( m => m.VerificationPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'profile/:id',
    children: [
      {
        path: '',
        loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'property/search',
    loadChildren: () => import('./property/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'property/submit',
    canActivate: [AuthGuard, VerifGuard],
    loadChildren: () => import('./property/submit/submit.module').then( m => m.SubmitPageModule)
  },
  {
    path: 'property/favorites',
    loadChildren: () => import('./property/favorites/favorites.module').then( m => m.FavoritesPageModule)
  },
  {
    path: "city/:city",
    loadChildren: () => import('./cities/cities.module').then( m => m.CitiesPageModule)
  },
  {
    path: "explore",
    loadChildren: () => import('./explore/explore.module').then( m => m.ExplorePageModule)
  },
  {
    path: 'property/:id',
    children: [
      {
        path: 'edit',
        canActivate: [AuthGuard, VerifGuard],
        loadChildren: () => import('./property/submit/submit.module').then( m => m.SubmitPageModule)
      },
      {
        path: ':title',
        loadChildren: () => import('./property/view/view.module').then( m => m.ViewPageModule)
      },
      {
        path: ':title/:address',
        loadChildren: () => import('./property/view/view.module').then( m => m.ViewPageModule)
      },
      {
        path: '',
        loadChildren: () => import('./property/view/view.module').then( m => m.ViewPageModule)
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
      }
    ]
  }
];

// Add the redirection for any unkonwn routes
routes.push({
  path: '**',
  redirectTo: '',
  pathMatch: 'full'
});

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabledBlocking' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
