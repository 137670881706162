import { Component, Inject, Input, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Navigation } from 'swiper';
import { IonicSlides } from '@ionic/angular';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Navigation, IonicSlides]);

@Component({
  selector: 'app-properties-swiper',
  templateUrl: 'properties-swiper.component.html',
  styleUrls: ['properties-swiper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PropertiesSwiperComponent {

  slideOpts = {
    slidesPerView: 'auto',
    autoplay: {
      delay: 5000,
      disableOnInteraction: true
    },
    centerInsufficientSlides: true,
    breakpoints: {
      1200: {
        slidesPerView: 4
      },
      992: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      0: {
        slidesPerView: 1
      }
    }
  }

  isSwiperBeginning = true;
  isSwiperEnd = false;
  swiperHasPages = true;

  isBrowser;

  // List of properties to show
  @Input() properties;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  
  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.swiper)
      this.onBreakpoint([this.swiper.swiperRef]);
    if (this.isBrowser) {
      setTimeout(() => {
        if (this.swiper)
          this.onBreakpoint([this.swiper.swiperRef]);
      }, 1000);
    }
  }

  onSlideChange() {
    if (this.swiper && this.swiper.swiperRef) {
      this.isSwiperBeginning = (this.swiper.swiperRef.isBeginning) ? true : false;
      this.isSwiperEnd = (this.swiper.swiperRef.isEnd) ? true : false;
    }
  }
  slideNext(){
    if (this.swiper && this.swiper.swiperRef)
      this.swiper.swiperRef.slideNext(200);
  }
  slidePrev(){
    if (this.swiper && this.swiper.swiperRef)
      this.swiper.swiperRef.slidePrev(200);
  }
  onBreakpoint(params) {
    if (!params || params.length <= 0 || !params[0] || !this.isBrowser)
      return;
    setTimeout(() => {
      this.swiperHasPages = (params[0].params.slidesPerView < params[0].slides.length) ? true : false;
      this.onSlideChange();
    }, 100);
  }

}
