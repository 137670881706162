<!-- Pagination -->
<ion-row class="slideHandles d-flex justify-content-end py-3" *ngIf="swiperHasPages">
	<ion-button color="tertiary" (click)="slidePrev()" [disabled]="isSwiperBeginning">
	  <ion-icon name="chevron-back-outline"></ion-icon>
	</ion-button>
	<ion-button color="tertiary" (click)="slideNext()" [disabled]="isSwiperEnd">
	  <ion-icon name="chevron-forward-outline"></ion-icon>
	</ion-button>
</ion-row>

<!-- Latest properties -->
<ion-row class="propertiesCarouselComp" *ngIf="properties && properties.length > 0">
	<ion-col size="12" class="p-0">
		<swiper #swiper [pagination]="true" [config]="slideOpts" class="full-width" (slidesGridLengthChange)="onBreakpoint($event)" (slideChange)="onSlideChange()" (breakpoint)="onBreakpoint($event)">
			<ng-template swiperSlide *ngFor="let property of properties">
				<app-property-card class="w-100 h-100" [property]="property"></app-property-card>
			</ng-template>
		</swiper>
	</ion-col>
</ion-row>