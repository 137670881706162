import { Component, Input, ViewChild } from '@angular/core';

import { ServerService } from '../../../services/server.service';

@Component({
  selector: 'app-properties-pages',
  templateUrl: 'properties-pages.component.html',
  styleUrls: ['properties-pages.component.scss']
})
export class PropertiesPagesComponent {

  // Nb properties per page
  @Input() nbPropertiesPerPage;
  // The params for the query
  @Input() params: any;

  // Search params
  paramsQ: any = {};
  // Properties displayed in the list
  properties;
  // Number of properties in total
  nbPropertiesTotal;
  // Current search page
  currentSearchPage;

  // Boolean if we are fetching the properties
  fetchingProperties = true;

  @ViewChild('pagination') pagination;

  constructor(private serverService: ServerService) {
  }

  ngOnInit() {
    if (this.params)
      this.paramsQ.currentCity = this.params.city;
    if (this.params)
      this.paramsQ.currentCountry = this.params.country;
    if (this.params.visibility)
      this.paramsQ.visibility = this.params.visibility;
    this.getProperties();
  }

  // Fetch the properties
  getProperties(optionalSearchPage = 0) {
    // Clear the list of properties
    this.properties = undefined;
    // Fetch the first page
    this.currentSearchPage = optionalSearchPage;
    // Show the spinner, hide the pagination buttons
    this.fetchingProperties = true;
    // Fetch the properties
    this.getPropertiesForPage().then((result: any) => {
      // Show the properties
      this.properties = result.results;
      // Save the number of properties in total
      this.nbPropertiesTotal = result.count;
      // Initialize the pagination with:
      // - the total number of documents
      // - the number of pages
      // - the first page results
      this.pagination.initialize(this.nbPropertiesTotal, result.nbPages, this.properties, this.currentSearchPage);
    }).finally(() => {
      // Hide the spinner
      this.fetchingProperties = false;
    });
  }

  // Fetch the properties
  getPropertiesForPage() {
    // Show the spinner, hide the pagination buttons
    this.fetchingProperties = true;
    // Fetch the properties
    var sparams: any = this.serverService.getSearchParameters(this.paramsQ, this.paramsQ.visibility);
    sparams.page = this.currentSearchPage + 1;
    sparams.page_size = this.nbPropertiesPerPage;
    return this.serverService.getProperties(sparams, true);
  }

  // Load the next page of properties
  // index: int  => page number user wants to jump to
  searchPage(index) {
    // Show the spinner, hide the pagination buttons
    this.fetchingProperties = true;
    // Set the current page
    this.currentSearchPage = index;
    // Fetch the properties
    this.getPropertiesForPage().then((result: any) => {
      // Show the properties
      this.properties = result.results;
      // Save the number of properties in total
      this.nbPropertiesTotal = result.count;
      // Save the properties so we won't need to request again if needed
      this.pagination.savePage(this.currentSearchPage, this.properties);
    }).finally(() => {
      // Hide the spinner
      this.fetchingProperties = false;
    });
  }

  // When we are loading a saved page (data already requested)
  loadSavedPage(properties) {
    // Add the new properties
    this.properties = properties;
  }

  indexChanged(index) {
    this.currentSearchPage = index;
  }

}
