import { Component, Inject, Input, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Navigation } from 'swiper';
import { IonicSlides } from '@ionic/angular';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Navigation, IonicSlides]);

@Component({
  selector: 'app-testimonials-swiper',
  templateUrl: 'testimonials-swiper.component.html',
  styleUrls: ['testimonials-swiper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TestimonialsSwiperComponent {

  slideOpts = {
    slidesPerView: 'auto',
    autoplay: {
      delay: 5000,
      disableOnInteraction: true
    },
    centerInsufficientSlides: true,
    breakpoints: {
      1200: {
        slidesPerView: 4
      },
      992: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      0: {
        slidesPerView: 1
      }
    }
  }

  isSwiperBeginning = true;
  isSwiperEnd = false;
  swiperHasPages = true;

  isBrowser;

  // List of testimonials to show
  @Input() testimonials = [{
    name: "John Clover",
    location: "Miami, FL",
    review: `Simply, the best investment I could make`
  },{
    name: "Clara Portland",
    location: "San Diego, CA",
    review: `I cannot thank you enough, you're amazing.
    <br/><br/>
    I will definitely recommend World Luxury Home to my colleagues`
  },{
    name: "Denis Louin",
    location: "Paris, France",
    review: `I was skeptical at first but one of my colleagues convinced me. I could not understand how you could make me sell faster, but Daniele was amazing in explaning the processes and right now I will never go back.
    <br/><br/>
    The average time that I need to sell a 2-million house has improved by a good 25%.
    <br/><br/>
    You're magical!`
  },{
    name: "Patrick James",
    location: "London, UK",
    review: `I got convinced to try WLH after seeing a billboard in London, though I was not sure it was a good investment. I have never been so wrong.
    <br/>
    There are no words to describe how well Daniele and his team work, the service is simply superb and the results are excellent.
    <br/>
    They gave me hundreds of free suggestions to improve my listings and the sale process, plus they found a buyer for a 13M property that has been in my portfolio for more than one year.`
  }];

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  
  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.swiper)
      this.onBreakpoint([this.swiper.swiperRef]);
    if (this.isBrowser) {
      setTimeout(() => {
        if (this.swiper)
          this.onBreakpoint([this.swiper.swiperRef]);
      }, 1000);
    }
  }

  onSlideChange() {
    if (this.swiper && this.swiper.swiperRef) {
      this.isSwiperBeginning = (this.swiper.swiperRef.isBeginning) ? true : false;
      this.isSwiperEnd = (this.swiper.swiperRef.isEnd) ? true : false;
    }
  }
  slideNext(){
    if (this.swiper && this.swiper.swiperRef)
      this.swiper.swiperRef.slideNext(200);
  }
  slidePrev(){
    if (this.swiper && this.swiper.swiperRef)
      this.swiper.swiperRef.slidePrev(200);
  }
  onBreakpoint(params) {
    if (!params || params.length <= 0 || !params[0] || !this.isBrowser)
      return;
    setTimeout(() => {
      this.swiperHasPages = (params[0].params.slidesPerView < params[0].slides.length) ? true : false;
      this.onSlideChange();
    }, 100);
  }

}
