import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-simple-list',
  templateUrl: 'simple-list.component.html',
  styleUrls: ['simple-list.component.scss'],
})
export class SimpleListComponent {

  items;

  constructor(private popoverController: PopoverController) {
  }

  closeMenu() {
  	if (this.popoverController != undefined && this.popoverController.dismiss != undefined)
  		this.popoverController.dismiss();
  }

  itemClicked(item) {
    if (item != undefined && item.onClicked != undefined)
      item.onClicked();
  }

}
