<!-- Spinner -->
<ion-grid *ngIf="fetchingProperties == true" class="ion-no-padding">
	<ion-row style="height: 61px">
		<ion-spinner class="mx-auto" name="crescent"></ion-spinner>
	</ion-row>
</ion-grid>

<!-- No properties found -->
<ion-grid *ngIf="fetchingProperties != true && nbPropertiesFound <= 0 && discret != true" style="padding-bottom: 0">
	<ion-row class="ion-justify-content-center">
		{{'GLOBAL.NO-PROPERTIES-FOUND' | translate}}
	</ion-row>
</ion-grid>

<!-- Properties found -->
<ion-grid *ngIf="nbPropertiesFound && nbPropertiesFound > 0" style="padding-bottom: 0">
	<!-- # of properties found -->
	<ion-row *ngIf="fetchingProperties != true && (discret != true || nbPages > 1)">
		<ion-label>
			{{(currentSearchPage * nbPropertiesPerPage) + 1}}
			-
			<ion-label *ngIf="((currentSearchPage + 1) * nbPropertiesPerPage) > nbPropertiesFound">{{nbPropertiesFound}}</ion-label>
			<ion-label *ngIf="((currentSearchPage + 1) * nbPropertiesPerPage) <= nbPropertiesFound">{{((currentSearchPage + 1) * nbPropertiesPerPage)}}</ion-label>
			{{'GLOBAL.ON' | translate}}
			<ion-label *ngIf="nbPropertiesFound == 1">{{nbPropertiesFound}} {{'PROP.PROPERTY' | translate}}</ion-label>
			<ion-label *ngIf="nbPropertiesFound != 1">{{nbPropertiesFound}} {{'PROP.PROPERTIES' | translate}}</ion-label>
		</ion-label>
	</ion-row>
	<!-- Pagination controls -->
	<ion-row *ngIf="fetchingProperties != true && nbPages >= 1">
		<!-- Previous page -->
		<ion-fab-button size="small" (click)="prevPage()" class="noShadow bordered" [disabled]="currentSearchPage <= 0">
			<ion-icon name="chevron-back"></ion-icon>
		</ion-fab-button>
		<!-- Page numbers -->
		<ion-label *ngFor="let item of propertiesPaged; let i = index">
			<ion-label *ngIf="math.abs(currentSearchPage - i) <= 1 || i == 0 || i == (nbPages - 1)" [ngClass]="{'currentPage': i == currentSearchPage}" (click)="exactPage(i)" class="pageLabel">{{i + 1}}</ion-label>
			<ion-label *ngIf="(math.abs(currentSearchPage - i) > 1 && i != 0 && i != (nbPages - 1)) && (i == 1 || i == (nbPages - 2))">...</ion-label>
		</ion-label>
		<!-- Next page -->
		<ion-fab-button size="small" (click)="nextPage()" class="noShadow bordered" [disabled]="currentSearchPage >= (nbPages - 1)">
			<ion-icon name="chevron-forward"></ion-icon>
		</ion-fab-button>
	</ion-row>
</ion-grid>