import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, ReplaySubject } from "rxjs";
import { take } from "rxjs/operators";
import { httpsCallable } from 'firebase/functions';

import { environment } from '../environments/environment';
import { AppSettings } from "../app/app.settings";

import { UserService } from "./user.service";
import { FirebaseService } from "./firebase.service";

const NB_PROPERTIES_PER_PAGE = 20;
const NB_PROPERTIES_PER_BATCH = 200;

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  // List of currencies
  currencies;
  public currencies_obs = new ReplaySubject<any>(1);

  // Properties index
  propertiesIndex;

  // Environment
  environment = environment;
  
	constructor(private appSettings: AppSettings, private http: HttpClient, private userService: UserService, private firebaseService: FirebaseService) {
    // Fetch currencies
    var headers = new HttpHeaders({
      'Cache-Control':  'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    this.http.get(this.environment.serverUrl + 'api/currencies/', {headers: headers}).pipe(take(1)).subscribe((res: any) => {
      if (res && res.count > 0) {
        this.currencies = res.results;
        this.currencies_obs.next(res.results);
      } else
        this.currencies_obs.next(undefined);
    }, (err) => {
      console.log(err);
      this.currencies_obs.next(undefined);
    });
  }

	// -------------------------------------------------------
  // Public functions
  // -------------------------------------------------------
  // Search the properties
  public getProperties(params, noCache = false) {
    return new Promise((resolve, reject) => {
      // Generate URL
      var url = (noCache) ? this.environment.serverUrl + 'api/properties/' : this.environment.serverUrl + 'public/api/properties/';
      var query = '';
      for (var key in params) {
        // If array
        if (Array.isArray(params[key])) {
          params[key].forEach((v) => {
            query += this.addQueryParam(key, v, query);
          });
        } else
          query += this.addQueryParam(key, params[key], query);
      }
      query += this.addQueryParam('timestamp', Date.now(), query);
      url += query;
      var nbPropPerPage = (params.page_size) ? params.page_size : NB_PROPERTIES_PER_PAGE;
      if (noCache) {
        var headers = new HttpHeaders({
          'Cache-Control':  'no-cache, max-age=0, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        });
      }
      this.http.get(url, {headers: headers}).subscribe((res: any) => {
        let nbPppage = (res && res.results.length > 0) ? res.results.length : nbPropPerPage;
        res.nbPages = Math.ceil(res.count / nbPppage) || 1;
        resolve(res);
      }, (err) => {
        console.log(err);
        reject(err);
      });
    });
  }

  // Search the properties and return all the result at once
  public getAllProperties(params, noCache = false) {
    return new Promise((resolve, reject) => {
      // Get first page
      params.page_size = 500;
      params.page = 1;
      this.getProperties(params, noCache).then((res: any) => {
        // If only one page, returns now
        if (res.nbPages <= 1)
          return resolve(res);
        else {
          let promises = [];
          for (var i = 2; i <= res.nbPages; i++)
            promises.push(this.getProperties({ ...params, page: i }, noCache));
          Promise.all(promises).then((res2) => {
            res2.forEach((res3) => {
              res.results = res.results.concat(res3.results);
            });
            res.nbPages = 1;
            res.next = null;
            return resolve(res);
          }, (err) => {
            return reject(err);
          });
        }
      }, (err) =>  {
        console.log(err);
        reject(err);
      });
    });
  }

  // Create or update a (new) property
  public createProperty(data, propId = undefined) {
    return new Promise((resolve, reject) => {
      this.userService.getIdToken().then((token) => {
        delete data.created_at;
        delete data.updated_at;
        if (data.images) {
          for (const imgIndex in data.images) {
            if (data.images[imgIndex] && data.images[imgIndex].url) {
              data.images[imgIndex].url_low = data.images[imgIndex].url;
              data.images[imgIndex].url_med = data.images[imgIndex].url;
              data.images[imgIndex].url_high = data.images[imgIndex].url;
            }
          }
        }
        if (propId)
          data.id = propId;
        if (data.available_from != undefined && data.available_from.length <= 0)
          delete data.available_from;
        if (propId) {
          // Update
          this.http.put(this.environment.serverUrl + 'api/properties/' + propId + '/', data, { headers: new HttpHeaders({ 'Authorization': token })}).subscribe((res) => {
            return resolve(res);
          }, (err) => {
            console.log(err);
            return reject(err);
          });
        } else {
          // Create
          this.http.post(this.environment.serverUrl + 'api/properties/', data, { headers: new HttpHeaders({ 'Authorization': token })}).subscribe((res) => {
            return resolve(res);
          }, (err) => {
            console.log(err);
            return reject(err);
          });
        }
      }, (err) => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // Delete a list of properties
  public deleteProperties(listOfIds) {
    return new Promise((resolve, reject) => {
      if (!listOfIds || listOfIds.length <= 0)
        return resolve([]);
      this.userService.getIdToken().then((token) => {
        var requests = [];
        listOfIds.forEach((pId) => {
          try {
            requests.push(this.http.delete(this.environment.serverUrl + 'api/properties/' + pId + '/', { headers: new HttpHeaders({'Authorization': token})}));
          } catch(err) {
            console.log(err);
            return reject(err);
          }
        });
        forkJoin(requests).subscribe(results => {
          if (results && results.length > 0) {
            const deleteImage = httpsCallable(this.firebaseService.functions, 'deleteImage');
            results.forEach((data: any) => {
              if (data && data.images && data.images.length > 0) {
                data.images.forEach((image) => {
                  if (image && image.path)
                    deleteImage({path: image.path});
                })
              }
            })
          }
          return resolve(results);
        }, (err) => {
          console.log(err);
          return reject(err);
        });
      }, (err) => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // Return an object with all the search parameters
  public getSearchParameters(params: any, visibility?) {
    // var filters = '', numericFilters = [], boundingBox;
    var res: any = {};
    // Set visibility
    if (visibility)
      res.visibility = visibility;
    // Set on_homepage
    if (params.on_homepage)
      res.on_homepage = params.on_homepage;
    // Set homepage
    if (params.homepage)
      res.homepage = params.homepage;
    // Set country filter
    if (params.currentCountry != undefined && params.currentCountry.length > 0)
      res.country = params.currentCountry;
    // Set city filter
    if (params.currentCity && params.currentCity.length > 0)
      res.s_city = params.currentCity;
    // Set title filter
    if (params.currentTitle && params.currentTitle.length > 0)
      res.s_title = params.currentTitle;
    // Set internal ID filter
    if (params.currentInternalId && params.currentInternalId.length > 0)
      res.s_internal_id = params.currentInternalId;
    // Set author_uid filter
    if (params.authorUid && params.authorUid.length > 0)
      res.author_uid = params.authorUid;
    // Set xmlId filter
    if (params.xmlId && params.xmlId.length > 0)
      res.xml_id = params.xmlId;
    if (params.no_xml && params.no_xml.length > 0)
      res.no_xml = params.no_xml;
    // Set type filter
    if (params.currentTypes != undefined && params.currentTypes.length > 0)
      res.s_types = params.currentTypes;
    // Set status filter
    if (params.currentStatus != undefined && params.currentStatus.length > 0)
      res.s_status = params.currentStatus;
    // Set # of bedrooms
    if (params.nbBedrooms && !isNaN(params.nbBedrooms))
      res.min_nb_bedrooms = params.nbBedrooms;
    // Set # of bathrooms
    if (params.nbBathrooms && !isNaN(params.nbBathrooms))
      res.min_nb_bathrooms = params.nbBathrooms;
    // Set price range
    if (params.priceRange && params.priceRange.lower != this.appSettings.MIN_PRICE && params.priceRange.upper != this.appSettings.MAX_PRICE) {
      res.min_price = params.priceRange.lower;
      res.max_price = params.priceRange.upper;
    }
    else if (params.priceRange && params.priceRange.lower != this.appSettings.MIN_PRICE)
      res.min_price = params.priceRange.lower;
    else if (params.priceRange && params.priceRange.upper != this.appSettings.MAX_PRICE)
      res.max_price = params.priceRange.upper;
    if (res.min_price == undefined || isNaN(res.min_price))
      delete res.min_price;
    if (res.max_price == undefined || isNaN(res.max_price))
      delete res.max_price;
    // Set the keywords
    if (params.currentKeywords != undefined && params.currentKeywords.length > 0)
      res.s_features = params.currentKeywords
    // If we are locating the search inside the map, set the bounding box
    if (params.lat_min != undefined && !isNaN(params.lat_min))
      res.lat_min = params.lat_min;
    if (params.lat_max != undefined && !isNaN(params.lat_max))
      res.lat_max = params.lat_max;
    if (params.lng_min != undefined && !isNaN(params.lng_min))
      res.lng_min = params.lng_min;
    if (params.lng_max != undefined && !isNaN(params.lng_max))
      res.lng_max = params.lng_max;
    return res;
  }

  // Get the products
  public getProducts() {
    return new Promise((resolve, reject) => {
      this.http.get(this.environment.serverUrl + 'public/api/products/').subscribe((res: any) => {
        var plans = [];
        if (res && res.results && res.results.length > 0) {
          plans = res.results.filter((p) => p && p.metadata && p.metadata.is_plan == "true");
          res.results.filter((p) => p && p.metadata && p.metadata.extra != undefined && p.metadata.for_plan != undefined).forEach((p) => {
            var forPlan = plans.filter((p2) => p2.name == p.metadata.for_plan);
            if (forPlan && forPlan.length > 0) {
              p.quantity = 0;
              forPlan[0].extras = forPlan[0].extras || {};
              forPlan[0].extrasArr = forPlan[0].extrasArr || [];
              forPlan[0].extras[p.metadata.extra] = p;
              forPlan[0].extrasArr.push(p);
            }
          });
        }
        resolve(plans);
      }, (err) => {
        console.log(err);
        reject(err);
      });
    });
  }

  // Convert from one currency to another
  // Example:  value=42   from='EUR'   to='JPY'
  public convertCurrency(value, from, to) {
    if (!this.currencies)
      return;
    if (from.toLowerCase() == to.toLowerCase())
      return value;
    var fromCurrency = this.currencies.filter((c) => c.name.toLowerCase() == from.toLowerCase()),
        toCurrency = this.currencies.filter((c) => c.name.toLowerCase() == to.toLowerCase());
    if (fromCurrency.length <= 0 || fromCurrency[0].value == undefined || toCurrency.length <= 0 || toCurrency[0].value == undefined)
      return;
    return this.intelliRound((value / fromCurrency[0].value) * toCurrency[0].value);
  }

  // Update the XML in database (in case properties have been created or deleted)
  updateXmlInDatabase(xmlId, isCreated = false) {
    this.firebaseService.getXml(xmlId).then((xml: any) => {
      if (!xml || !xml.data)
        return;
      let xmlData = xml.data();
      // Fetch the new created properties
      this.getAllProperties({ xml_id: xmlId }).then((newProps: any) => {
        if (newProps.results && newProps.results.length <= 0) {
          this.firebaseService.deleteXml(xmlId);
          return;
        }
        // Update the list of properties ID
        xmlData.propertiesIds = newProps.results.map((p) => p.objectID);
        if (isCreated != true)
          delete xmlData.created_at;
        xmlData.updated_at = new Date().getTime();
        this.firebaseService.updateXml(xmlId, xmlData);
      });
    });
  }

  // -------------------------------------------------------
  // Admin functions
  // -------------------------------------------------------
  // Update/Create a list of properties
  public updatePropertiesAdmin(listOfIds, changes: any[]) {
    return new Promise((resolve, reject) => {
      if (!listOfIds || listOfIds.length <= 0)
        return resolve([]);
      this.userService.getIdToken().then((token) => {
        var requests = [];
        listOfIds.forEach((pId, index) => {
          try {
            const data = changes[index];
            delete data.created_at;
            delete data.updated_at;
            if (data.available_from != undefined && data.available_from.length <= 0)
              delete data.available_from;
            data.id = pId;
            requests.push(data);
          } catch(err) {
            console.log(err);
            return reject(err);
          }
        });
        if (requests.length > 0) {
          var batchedRequests = [];
          while (requests.length > 0) {
            batchedRequests.push(
              this.http.post(this.environment.serverUrl + 'batch/api/properties/', requests.splice(0, NB_PROPERTIES_PER_BATCH), { headers: new HttpHeaders({'Authorization': token})})
            );
          }
          forkJoin(batchedRequests).subscribe(results => {
            return resolve(results);
          }, err => {
            console.log(err);
            return reject(err);
          });
        } else
          return resolve([]);
      }, (err) => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // Patch properties
  public patchPropertiesAdmin(listOfIds, change: any) {
    return new Promise((resolve, reject) => {
      if (!listOfIds || listOfIds.length <= 0)
        return resolve([]);
      this.userService.getIdToken().then((token) => {
          this.http.patch(this.environment.serverUrl + 'batch/api/properties/', { ids: listOfIds, change: change }, { headers: new HttpHeaders({'Authorization': token})}).subscribe(results => {
            return resolve(results);
          }, err => {
            console.log(err);
            return reject(err);
          });
      }, (err) => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // Delete a list of properties
  public deletePropertiesAdmin(listOfIds) {
    return new Promise((resolve, reject) => {
      if (!listOfIds || listOfIds.length <= 0)
        return resolve([]);
      this.userService.getIdToken().then((token) => {
        var requests = [];
        listOfIds.forEach((pId) => {
          try {
            requests.push(this.http.delete(this.environment.serverUrl + 'batch/api/properties/' + pId + '/', { headers: new HttpHeaders({'Authorization': token})}));
          } catch(err) {
            console.log(err);
            return reject(err);
          }
        });
        forkJoin(requests).subscribe(results => {
          if (results && results.length > 0) {
            const deleteImage = httpsCallable(this.firebaseService.functions, 'deleteImage');
            results.forEach((data: any) => {
              if (data && data.images && data.images.length > 0) {
                data.images.forEach((image) => {
                  if (image && image.path)
                    deleteImage({path: image.path});
                })
              }
            })
          }
          return resolve(results);
        }, (err) => {
          console.log(err);
          return reject(err);
        });
      }, (err) => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // -------------------------------------------------------
  // Private functions
  // -------------------------------------------------------
  // Add query string parameter
  private addQueryParam(key, value, url) {
    return ((!url || url.length <= 0) ? '?' : '&') + key + '=' + encodeURIComponent(value);
  }
  private intelliRound(num) {
    var len = (Math.abs(Math.round(num)) + '').length;
    if (len >= 4)
      len -= 3;
    var fac = Math.pow(10, len);
    return Math.round(num / fac) * fac;
  }

}