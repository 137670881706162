import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { google } from 'google-maps';
import { isPlatformBrowser } from '@angular/common';

import { I18NService } from '../services/i18n.service';
import { MetasService } from '../services/metas.service';
import { WindowService } from '../services/window.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  google: google;

  // Subscriptions
  subs = [];

  isDesktop;
  isBrowser;

  constructor(public alertController: AlertController, private metasService: MetasService,
              private i18NService: I18NService, private windowService: WindowService,
              @Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    const window: Window = this.windowService.getWindow();
    if (this.isBrowser && window && window.innerWidth != undefined)
      this.windowService.updateWindowSize(window.innerWidth);
    this.initializeApp();
    if (this.isBrowser) {
      // Watch language
      setTimeout(() => {
        this.subs.push(this.i18NService.lang_obs.subscribe(() => {
          this.metasService.setHomeMetas();
        }));
      }, 5000);
    }
  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
    // Clear all the subscriptions
    this.subs.forEach(function(sub) {
      if (sub && sub.unsubscribe)
        sub.unsubscribe();
    });
  }

  initializeApp() {
    
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.isBrowser && window)
      this.windowService.updateWindowSize(window.innerWidth);
  }

}