import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'social-auth-buttons',
  templateUrl: 'social-auth-buttons.component.html',
  styleUrls: ['social-auth-buttons.component.scss'],
})
export class SocialAuthButtonsComponent implements OnInit, OnDestroy {

  // The type: Register or Login
  @Input() type;

  // Event emitted when trying to use Google
  @Output() google: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

  goWithGoogle() {
    this.google.emit();
  }

}
