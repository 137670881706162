import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: 'pagination.component.html',
  styleUrls: ['pagination.component.scss'],
})
export class PaginationComponent {

  // Math instance
  math = Math;

  // List of properties saved by page (to avoid requesting again)
  propertiesPaged;
  // Number total of properties
  nbPropertiesFound;
  // Number of pages
  nbPages;
  // Current page index
  currentSearchPage;

  // Number of properties per page
  @Input() nbPropertiesPerPage;
  // Boolean if we are fetching the data
  @Input() fetchingProperties;
  // Boolean to make the component discret (no text displayed) if empty data
  @Input() discret = false;

  // Event emitted when a new page is requested
  // In that case, we need to request the backend for the list of properties
  @Output() newPageChanged: EventEmitter<any> = new EventEmitter();

  // Event emitted when an "already viewed" page is requested
  // In that case, we send the saved data in the event, so no need to request the backend again
  @Output() alreadyViewedPageChanged: EventEmitter<any> = new EventEmitter();

  // Event emitted everytime a page is changed, no matter what
  @Output() indexChanged: EventEmitter<any> = new EventEmitter();

  constructor() {
  }


  // -------------------------------------------------------
  // Public functions (for the parent component)
  // -------------------------------------------------------
  // Initialize the pagination component
  initialize(nbPropertiesFound, nbPages, firstPageProperties, currentSearchPage = 0) {
    // Update our variables
    this.nbPropertiesFound = nbPropertiesFound;
    this.nbPages = nbPages;
    // Check current search page
    if (currentSearchPage >= nbPages) {
      this.currentSearchPage = nbPages - 1;
      this.indexChanged.emit(this.currentSearchPage);
    } else
      this.currentSearchPage = currentSearchPage;
    // Save the first page
    this.propertiesPaged = new Array(this.nbPages);
    if (this.nbPages > this.currentSearchPage)
      this.propertiesPaged[this.currentSearchPage] = firstPageProperties;
  }

  // Clear the pagination
  clear() {
    this.fetchingProperties = false;
    this.nbPropertiesFound = undefined;
  }

  // Remove a saved page
  removePage(index) {
    if (index < 0 || index >= this.nbPages)
      return;
    // Remove the page
    this.propertiesPaged[index] = undefined;
  }

  // Save a new page with those properties
  savePage(index, properties) {
    if (index < 0 || index >= this.nbPages)
      return;
    // Save the new page
    this.propertiesPaged[index] = properties;
  }

  // Blindly set the current page index
  setCurrentPageIndex(index) {
    if (index < 0 || index >= this.nbPages)
      return;
    // Set the current page
    this.currentSearchPage = index;
  }





  // -------------------------------------------------------
  // Private functions
  // -------------------------------------------------------
  // When user wants to go to previous page
  prevPage() {
    // Check if we are not already on first page
    if (this.currentSearchPage <= 0)
      return;
    // Change the current page
    this.currentSearchPage -= 1;
    // If we need to fetch new data
    if (this.propertiesPaged[this.currentSearchPage] == undefined)
      this.newPageChanged.emit(this.currentSearchPage);
    else
      this.alreadyViewedPageChanged.emit(this.propertiesPaged[this.currentSearchPage]);
    // Current page index changed, trigger the event
    this.indexChanged.emit(this.currentSearchPage);
  }

  // When user wants to go to next page
  nextPage() {
    // Check if we are not already on last page
    if (this.currentSearchPage >= (this.nbPages - 1))
      return;
    // Change the current page
    this.currentSearchPage += 1;
    // If we need to fetch new data
    if (this.propertiesPaged[this.currentSearchPage] == undefined)
      this.newPageChanged.emit(this.currentSearchPage);
    else
      this.alreadyViewedPageChanged.emit(this.propertiesPaged[this.currentSearchPage]);
    // Current page index changed, trigger the event
    this.indexChanged.emit(this.currentSearchPage);
  }

  // When user wants to go to an exact page
  exactPage(index) {
    if (index == undefined || index < 0 || index >= this.nbPages)
      return;
    // Change the current page
    this.currentSearchPage = index;
    // If we need to fetch new data
    if (this.propertiesPaged[this.currentSearchPage] == undefined)
      this.newPageChanged.emit(this.currentSearchPage);
    else
      this.alreadyViewedPageChanged.emit(this.propertiesPaged[this.currentSearchPage]);
    // Current page index changed, trigger the event
    this.indexChanged.emit(this.currentSearchPage);
  }
}
