import { ApplicationRef, Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

import { WindowService } from './window.service';
import { LocalstorageService } from "./localstorage.service";

@Injectable({
    providedIn: 'root'
  })
export class ThemeService {

    theme = new BehaviorSubject(false);
    public themeValue = false;

    windowRef;

    isBrowser;

    darkThemeLocalstorageKey = 'dark';

    constructor(private ref: ApplicationRef, private windowService: WindowService, @Inject(PLATFORM_ID) private platformId: object,
                private localStorage: LocalstorageService) {
        this.windowRef = this.windowService.getWindow();
        this.isBrowser = isPlatformBrowser(this.platformId);
        var storageValue = null;
        storageValue = this.localStorage.getItem(this.darkThemeLocalstorageKey);

        // If we have a value from localstorage, use it
        if (storageValue != null) {
            this.setDarkTheme((storageValue == 'true') ? true : false);
        }
        // Else, use the theme from the system
        else {
            if (this.isBrowser && this.windowRef) {
                const darkModeOn = this.windowRef.matchMedia && this.windowRef.matchMedia("(prefers-color-scheme: dark)").matches;
                // If dark mode is enabled then directly switch to the dark-theme
                this.setDarkTheme((darkModeOn) ? true : false);
            }
        }

        // Watch for changes of the preference
        if (this.isBrowser && this.windowRef) {
            this.windowRef.matchMedia("(prefers-color-scheme: dark)").addListener(e => {
                // If we have overwrote the setting, skip
                if (storageValue != null)
                    return;
                const turnOn = e.matches;
                if (turnOn)
                    this.setDarkTheme(true);
                else
                    this.setDarkTheme(false);
                // Trigger refresh of UI
                this.ref.tick();
            });
        }
    }

    public setDarkTheme(value, updateLocalstorage = false) {
        if (this.isBrowser && value == true && document)
            document.body.classList.add('dark');
        else if (this.isBrowser && document)
            document.body.classList.remove('dark');
        this.themeValue = value;
        this.theme.next(value);
        // Update localstorage
        if (updateLocalstorage)
            this.localStorage.setItem(this.darkThemeLocalstorageKey, (value == true) ? 'true' : 'false');
    }
}