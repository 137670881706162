import { Component, Input } from '@angular/core';

@Component({
  selector: 'profile-picture',
  templateUrl: 'profile-picture.component.html',
  styleUrls: ['profile-picture.component.scss'],
})
export class ProfilePictureComponent {

  @Input() imageUrl: string = 'assets/icon/profile.svg';
  @Input() size: number = 100;

  constructor() {}

  public showErrImage() {
    this.imageUrl = "assets/images/not-found.jpg";
  }

}
