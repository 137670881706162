import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from "@angular/router";

import { I18NService } from "./i18n.service";

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetasService {

    // Language
    lang;

    isBrowser;

    // Environment
    public environment = environment;

    constructor(@Inject(PLATFORM_ID) platformId: Object, private i18NService: I18NService, private meta: Meta,
                @Inject(DOCUMENT) private document: Document, private router: Router, private titleService: Title) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.lang = this.i18NService.currentLanguage;
        // Watch language
        this.i18NService.lang_obs.subscribe((lang) => {
            this.lang = lang;
            // this._setDefaultMetas();
        }, (err) => {
            console.log(err);
        });
        // Watch navigation end
        this.watchTitlesMeta();
    }

    // -------------------------------------------------------
    // Public functions
    // -------------------------------------------------------
    public setHomeMetas(metaNames = []) {
        // this._setDefaultMetas();
        this.i18NService.getMetas().then((metas: any) => {
            for (const metaName in metas) {
                var names = metaName.split('|');
                names.forEach((name) => {
                    if (!names || names.length <= 0 || metaNames.indexOf(name) != -1)
                        this.meta.updateTag({ name: name, property: name, content: metas[metaName] });
                });
            }
        });
    }

    // Update the description with the string passed in parameter
    public updateDesc(string) {
        // If no string, set default title metas
        if (!string)
            this.setHomeMetas(['description', 'og:description', 'twitter:description']);
        else {
            this.meta.updateTag({ name: 'description', property: 'description', content: string });
            this.meta.updateTag({ name: 'og:description', property: 'og:description', content: string });
            this.meta.updateTag({ name: 'twitter:description', property: 'twitter:description', content: string });
        }
    }

    // Update the title with the string passed in parameter
    public updateTitle(string) {
        // If no string, set default title metas
        if (!string) {
            this.setHomeMetas(['title', 'og:title', 'twitter:title']);
        } else {
            this.titleService.setTitle(string + ' - WLH');
            this.meta.updateTag({ name: 'title', property: 'og:title', content: string + ' - WLH' });
            this.meta.updateTag({ name: 'og:title', property: 'og:title', content: string + ' - WLH' });
            this.meta.updateTag({ name: 'twitter:title', property: 'twitter:title', content: string + ' - WLH' });
        }
    }

    // Update the image with the string passed in parameter
    public updateImage(url = undefined) {
        if (!url) {
            this.meta.updateTag({ name: 'image', property: 'og:image', content: 'https://worldluxuryhome.com/assets/images/world-luxury-home.jpg' });
            this.meta.updateTag({ name: 'og:image', property: 'og:image', content: 'https://worldluxuryhome.com/assets/images/world-luxury-home.jpg' });
            this.meta.updateTag({ name: 'twitter:image', property: 'twitter:image', content: 'https://worldluxuryhome.com/assets/images/world-luxury-home.jpg' });
        } else {
            this.meta.updateTag({ name: 'image', property: 'og:image', content: url });
            this.meta.updateTag({ name: 'og:image', property: 'og:image', content: url });
            this.meta.updateTag({ name: 'twitter:image', property: 'twitter:image', content: url });
        }
    }

    // Update the url with the string passed in parameter
    public updateUrl(url = '') {
        this.meta.updateTag({ name: 'url', property: 'og:url', content: this.environment.serverUrl + url.slice(1) });
        this.meta.updateTag({ name: 'og:url', property: 'og:url', content: this.environment.serverUrl + url.slice(1) });
        this.meta.updateTag({ name: 'twitter:url', property: 'twitter:url', content: this.environment.serverUrl + url.slice(1) });
    }

    // -------------------------------------------------------
    // Private functions
    // -------------------------------------------------------
    // After each page navigation, watch the <ion-title> or the <h1> to update title meta
    private watchTitlesMeta() {
        this.router.events.subscribe(value => {
            // After each end of navigation
            if (value instanceof NavigationEnd) {
                // If property page
                if (value.url.startsWith('/property/') && !value.url.startsWith('/property/search') &&
                    !value.url.startsWith('/property/submit') && !value.url.startsWith('/property/favorites'))
                    return;
                this.updateImage();
                this.updateUrl(value.url);
                // If home page
                if (value.url == '/') {
                    this.updateTitle(undefined);
                    this.updateDesc(undefined);
                    return;
                }
                if (this.isBrowser) {
                    // Else, other routes
                    setTimeout(() => {
                        try {
                            // Try to get the <ion-title>
                            let title = this.document.getElementsByTagName('ion-title');
                            if (title && title.length > 0 && title[0]?.textContent || title[0].innerText)
                                this.updateTitle(title[0]?.textContent || title[0].innerText);
                        } catch(err) {
                            try {
                                // Cannot find a <ion-title>, try to fetch a <h1>
                                let h1s = this.document.getElementsByTagName('h1');
                                if (h1s && h1s.length > 2 && (h1s[2]?.textContent || h1s[2].innerText))
                                    this.updateTitle(h1s[2]?.textContent || h1s[2].innerText);
                            } catch(err) {
                                this.updateTitle(undefined);
                            }
                        }
                    }, 300);
                } else
                    this.updateTitle(undefined);
            }
        });
    }
}
