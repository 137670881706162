import { Component, Input } from '@angular/core';
import { AppSettings } from '../../../app/app.settings';

import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-property-card',
  templateUrl: 'property-card.component.html',
  styleUrls: ['property-card.component.scss'],
})
export class PropertyCardComponent {

  // The proeprty to display
  @Input() property;

  // Boolean if we want shadow on the card
  @Input() shadow: Boolean = false;

  constructor(private appSettings: AppSettings, private utilsService: UtilsService) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  propHasBedrooms(property) {
    return (property.nb_bedrooms != undefined && property.nb_bedrooms > 0);
  }
  propHasBathrooms(property) {
    return (property.nb_bathrooms != undefined && property.nb_bathrooms > 0);
  }
  propHasSurface(property) {
    return (property.size_in != undefined &&
            ((property.size_in.length > 0 && property.size_in != '0') ||
             (!isNaN(property.size_in) && property.size_in > 0)
            ) && property.measurement_unit);
  }

  getPropertyUrl(property) {
    var url = '/property/' + property.id;
    if (property.title && property.title.replace)
      url += '/' + this.utilsService.trimString(property.title);
    if (property.city && property.country)
      url += '/' + this.utilsService.trimString(property.city) + '-' + this.utilsService.trimString(this.appSettings.getCountryName(property.country));
    return url;
  }


  // -------------------------------------------------------
  // Image functions
  // -------------------------------------------------------
  public showErrImageParent(img) {
    img.parentNode.setAttribute('style', 'background: url("assets/images/not-found.jpg")');
    img.setAttribute('style', 'display: none');
  }
}
