import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService implements Storage {

    private storage: Storage;
    [name: string]: any;
    length: number;

    isBrowser;

	constructor(@Inject(PLATFORM_ID) private platformId: object) {
        this.isBrowser = isPlatformBrowser(this.platformId);

        if (this.isBrowser)
            this.storage = localStorage;
	}

    // -------------------------------------------------------
    // Public functions
    // -------------------------------------------------------
    getItem(key: string): string | null {
        if (!this.isBrowser)
            return;
        return this.storage.getItem(key);
    }

    key(index: number): string | null {
        if (!this.isBrowser)
            return;
        return this.storage.key(index);
    }

    removeItem(key: string): void {
        if (!this.isBrowser)
            return;
        return this.storage.removeItem(key);
    }

    setItem(key: string, value: string): void {
        if (!this.isBrowser)
            return;
        return this.storage.setItem(key, value);
    }

    clear(): void {
        if (!this.isBrowser)
            return;
        this.storage.clear();
    }

    // -------------------------------------------------------
    // Private functions
    // -------------------------------------------------------


}