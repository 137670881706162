import { NgModule, APP_ID } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { provideClientHydration } from '@angular/platform-browser';
// import { ZoneTaskTrackingModule } from 'ngx-zone-task-tracking';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { WindowService } from '../services/window.service';

import { ComponentsModule } from './_components/components.module';

import { translateBrowserLoaderFactory } from '../shared/translate-browser.loader';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateBrowserLoaderFactory,
                deps: [HttpClient, TransferState]
            },
            defaultLanguage: 'en'
        }),
        // ZoneTaskTrackingModule.printWithDelay(),
        IonicModule.forRoot({ mode: 'md' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        ComponentsModule,
        HttpClientModule
    ],
    providers: [
        { provide: APP_ID, useValue: 'world-luxury-home' },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        // provideClientHydration(),
        WindowService,
        TranslatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}