<div class="ion-padding-vertical">
  <ion-button expand="block" class="ion-no-margin" color="google" (click)="goWithGoogle()">
    <ion-icon slot="start" name="logo-google"></ion-icon> {{('AUTH.' + (type | uppercase)) | translate}} {{'GLOBAL.WITH' | translate}} Google
  </ion-button>
</div>

<!-- <div>
  <ion-button expand="block" class="ion-no-margin" color="facebook" disabled>
    <ion-icon slot="start" name="logo-facebook"></ion-icon> {{('AUTH.' + (type | uppercase)) | translate}} {{'GLOBAL.WITH' | translate}} Facebook
  </ion-button>
</div> -->

<!-- <div class="ion-padding-vertical">
  <ion-button expand="block" class="ion-no-margin" color="twitter" disabled>
    <ion-icon slot="start" name="logo-twitter"></ion-icon> {{('AUTH.' + (type | uppercase)) | translate}} {{'GLOBAL.WITH' | translate}} Twitter
  </ion-button>
</div> -->