import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Pipe({
    name: 'price'
})
export class PricePipe implements PipeTransform {

    private sub;

    constructor(private serverService: ServerService, private userService: UserService, private currencyPipe: CurrencyPipe) {}

    transform(value: any, fromCurrency?: any): Observable<any> {
        return new Observable((observer) => {
            observer.next(this.currencyPipe.transform(value, fromCurrency, 'symbol', '1.0-0'));
            // Wait for everything to convert price
            this.serverService.currencies_obs.pipe(take(1)).subscribe(() => {
                this.sub = this.userService.displayCurrency_obs.subscribe((currency: string) => {
                    let v = this.serverService.convertCurrency(value, fromCurrency, currency);
                    if (v && !isNaN(v))
                        observer.next(this.currencyPipe.transform(Math.round(v), currency, 'symbol', '1.0-0'));
                    else
                        observer.next(this.currencyPipe.transform(value, fromCurrency, 'symbol', '1.0-0'));
                });
            });
        });
    }

    ngOnDestroy() {
        if (this.sub && this.sub.unsubscribe)
            this.sub.unsubscribe();
    }
}