import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, PopoverController, NavController } from '@ionic/angular';

import { UserService } from '../../../services/user.service';
import { ToastService } from '../../../services/toast.service';
import { ThemeService } from '../../../services/theme.service';
import { I18NService } from '../../../services/i18n.service';
import { WindowService } from '../../../services/window.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: 'profile-menu.component.html',
  styleUrls: ['profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit, OnDestroy {

	// Current user
	fetchingUser = true;
  user;

  // Language
  lang;

  // Boolean if dark theme is enabled
  darkTheme;

  isDesktop;

  showAdmin: Boolean = false;

  // Subscriptions
  subs = [];

  constructor(private userService: UserService, private popoverController: PopoverController, public alertController: AlertController,
  						private toastService: ToastService, private navCtrl: NavController, private windowService: WindowService,
              private themeService: ThemeService, public i18NService: I18NService) {}

  ngOnInit() {
    // Watch isDesktop
    this.subs.push(this.windowService.isDesktop.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    }));
    this.lang = this.i18NService.currentLanguage;
  	// Watch user
    this.subs.push(this.userService.user_obs.subscribe((user) => {
    	this.fetchingUser = false;
      this.user = user;
    }, (err) => {
    	this.fetchingUser = false;
    	this.user = undefined;
    }));
    // Watch for the theme
    this.subs.push(this.themeService.theme.subscribe((dark: boolean) => {
      this.darkTheme = dark;
    }));
    // Watch language
    this.subs.push(this.i18NService.lang_obs.subscribe((lang) => {
      this.lang = lang;
    }, (err) => {
      console.log(err);
    }));
  }

  ngOnDestroy() {
    // Clear all the subscriptions
    this.subs.forEach(function(sub) {
      if (sub && sub.unsubscribe)
        sub.unsubscribe();
    });
  }

  languageChanged(event) {
    if (!event || !event.detail || !event.detail.value || event.detail.value == this.lang)
      return;
    this.i18NService.changeLanguage(event.detail.value);
  }

  closeMenu() {
  	if (this.popoverController != undefined && this.popoverController.dismiss != undefined)
  		this.popoverController.dismiss();
  }

  logout() {
    // Prompt a confirm alert
    this.toastService.showAlert('SURE-LOGOUT', {
      header: 'AUTH.LOGOUT',
      buttons: [
        { text: 'GLOBAL.NO', role: 'cancel' }, {
          text: 'GLOBAL.YES',
          handler: () => {
            // Logout the user
            this.userService.logout().then(() => {
              this.toastService.showToast('ACCOUNT_DISCONNECTED', { color: 'success' });
              this.navCtrl.navigateRoot('');
            }, err => {
              this.toastService.showToast('CANNOT_DISCONNECTED', { color: 'danger' });
            });
          }
        }
      ]
    });
  }

  toggleTheme() {
    this.themeService.setDarkTheme(this.darkTheme, true);
  }

}
