import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { I18NService } from './i18n.service';

// Default toast duration (in ms)
const DEFAULT_TOAST_DURATION = 3000;

@Injectable({
	providedIn: 'root'
})
export class ToastService {

	private messages = {};

	private defaultOptions = {
		duration: DEFAULT_TOAST_DURATION,
		color: 'light',
		showCloseButton: true
	};

	// Language
    lang;

	// Boolean to limit the number of toasts that can pop
	canCreateToast = true;
	// Boolean to limit the number of alerts that can pop
	canCreateAlert = true;

	constructor(private toastController: ToastController, private alertController: AlertController, private i18NService: I18NService,
				private translateService: TranslateService) {
		this.lang = this.i18NService.currentLanguage;
		this.loadMessages();
        // Watch language
        this.i18NService.lang_obs.subscribe((lang) => {
            this.lang = lang;
			this.loadMessages();
        }, (err) => {
            console.log(err);
        });
	}

	// -------------------------------------------------------
	// Public functions
	// -------------------------------------------------------
	public showToast(errorId, options?) {
		var opts = {...JSON.parse(JSON.stringify(this.defaultOptions)), ...options};
		// If cannot create a new toast, abort
		if (this.canCreateToast != true)
			return;
		this.canCreateToast = false;
		if (options.dontTranslateMsg != true)
			opts.message = this.getMessagesString(errorId);
		// Create and display toast
		this.toastController.create(opts).then((toast) => {
			toast.present();
			// When toast will disappear
			toast.onDidDismiss().then(() => {this.canCreateToast = true;});
			toast.onWillDismiss().then(() => {this.canCreateToast = true;});
		}, (err) => {
			this.canCreateToast = true;
		});
	}

	public showAlert(errorId, options?) {
		var opts = options || {};
		if (opts.header)
			opts.header = this.getMessagesString(opts.header);
		if (opts.inputs && Array.isArray(opts.inputs)) {
			opts.inputs = opts.inputs.map((i) => {
				if (i.label)
					i.label = this.getMessagesString(i.label);
				return i;
			});
		}
		if (opts.buttons && Array.isArray(opts.buttons)) {
			opts.buttons = opts.buttons.map((b) => {
				if (b.text)
					b.text = this.getMessagesString(b.text);
				else if (typeof b == 'string')
					return this.translateService.instant(b);
				return b;
			});
		}
		// If cannot create a new alert, abort
		if (this.canCreateAlert != true)
			return;
		this.canCreateAlert = false;
		// Check error id
		if (errorId == undefined)
			return;
		if ((typeof errorId != 'object' || errorId.length == undefined || errorId.length <= 0) && typeof errorId != 'string')
			return;
		// Set message
		opts.message = (options.dontTranslateMsg != true) ? this.getMessagesString(errorId) : errorId;
		// Create and display alert
		this.alertController.create(opts).then((alert) => {
			// Show the alert
			alert.present();
			// When alert will disappear
			alert.onDidDismiss().then(() => {this.canCreateAlert = true;});
			alert.onWillDismiss().then(() => {this.canCreateAlert = true;});
		}, (err) => {
			this.canCreateAlert = true;
		});
	}

	// -------------------------------------------------------
	// Private functions
	// -------------------------------------------------------
	private loadMessages() {
		this.translateService.get('TOAST').pipe(take(1)).subscribe((data) => {
			this.messages = data;
		});
	}
	private getMessagesString(ids) {
		if (typeof ids == 'object' && ids.length != undefined && ids.length >= 0) {
			let res = '';
			ids.forEach((id) => {
				res += this.getMessageString(id);
			});
			return res;
		}
		else
			return this.getMessageString(ids);
	}
	private getMessageString(id) {
		if (this.messages[id])
			return this.messages[id];
		else
			return this.translateService.instant(id);
	}

}