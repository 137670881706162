import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';

import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private userService: UserService, private router: Router) {
	}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		let connected = await this.userService.isLoggedIn();
		if (connected != true)
			this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
		return connected;
	}

}
