import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { SwiperModule } from 'swiper/angular';
import { MatIconModule } from '@angular/material/icon';
import { SafePipeModule } from 'safe-pipe';

import { createTranslateLoader } from '../app.module';
import { PipesModule } from '../../pipes/pipes.module';

import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { SimpleListComponent } from './simple-list/simple-list.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { PaginationComponent } from './pagination/pagination.component';
import { AdminActionMenuComponent } from './admin-action-menu/admin-action-menu.component';
import { FooterComponent } from './footer/footer.component';
import { SocialAuthButtonsComponent } from './social-auth-buttons/social-auth-buttons.component';
import { PropertiesCarouselComponent } from './properties-carousel/properties-carousel.component';
import { UsersCarouselComponent } from './users-carousel/users-carousel.component';
import { HeaderComponent } from './header/header.component';
import { PropertyCardComponent } from './property-card/property-card.component';
import { CityCardComponent } from './city-card/city-card.component';
import { PropertiesPagesComponent } from './properties-pages/properties-pages.component';
import { PropertiesSwiperComponent } from './properties-swiper/properties-swiper.component';
import { TestimonialCardComponent } from './testimonial-card/testimonial-card.component';
import { TestimonialsSwiperComponent } from './testimonials-swiper/testimonials-swiper.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        FormsModule,
        SafePipeModule,
        MatIconModule,
        SwiperModule,
        PipesModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
    ],
    declarations: [
        ProfilePictureComponent,
        ProfileMenuComponent,
        SimpleListComponent,
        PaginationComponent,
        AdminActionMenuComponent,
        FooterComponent,
        SocialAuthButtonsComponent,
        PropertiesCarouselComponent,
        UsersCarouselComponent,
        HeaderComponent,
        PropertyCardComponent,
        CityCardComponent,
        PropertiesPagesComponent,
        PropertiesSwiperComponent,
        TestimonialCardComponent,
        TestimonialsSwiperComponent
    ],
    exports: [
        ProfilePictureComponent,
        ProfileMenuComponent,
        SimpleListComponent,
        PaginationComponent,
        AdminActionMenuComponent,
        FooterComponent,
        SocialAuthButtonsComponent,
        FormsModule,
        PropertiesCarouselComponent,
        UsersCarouselComponent,
        HeaderComponent,
        PropertyCardComponent,
        CityCardComponent,
        PropertiesPagesComponent,
        PropertiesSwiperComponent,
        TestimonialCardComponent,
        TestimonialsSwiperComponent
    ]
})
export class ComponentsModule { }