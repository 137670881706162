import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor() {}

    public trimString(string) {
        const regexes = [{
            from: / /g,
            into: '-'
        },{
            from: /,/g
        },{
            from: /;/g
        },{
            from: /\./g
        },{
            from: /\|/g
        },{
            from: /-{2,}/g,
            into: '-'
        }];
        regexes.forEach((regex) => {
            string = string.replace(regex.from, regex.into || '');
        });
        return string.toLowerCase();
    }

}