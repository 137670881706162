import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserService } from '../../../services/user.service';
import { I18NService } from '../../../services/i18n.service';
import { AppSettings } from '../../../app/app.settings';
import { FirebaseService } from '../../../services/firebase.service';

const NB_CITIES_MAX = 75;

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent {

  // Language
  lang;

  // Currencies
  currencies;
  // Currency
  currency;

  // Displayed cities
  cities;
  countries = [];
  tempCountries = {};

  // Subscriptions
  subs = [];

  @Input() showCities: Boolean = false;

  public categories = [{
    name: 'FOOTER.COMPANY',
    links: [{
      url: '/why-choose-us',
      label: 'FOOTER.WHY-CHOOSE-US'
    },{
      url: '/pricing',
      label: 'APP.PRICING'
    },{
      href: 'https://blog.worldluxuryhome.com/',
      label: 'Blog',
      target: '_blank',
      rel: "noreferrer"
    },{
      href: 'https://support.worldluxuryhome.com/',
      label: 'Support',
      target: '_blank',
      rel: "noreferrer"
    },{
      url: '/what-can-i-list-on-world-luxury-home',
      label: 'HELP-YOU.TITLE-0'
    }]
  },{
    name: 'FOOTER.SUPPORT',
    links: [{
      url: '/contact-us',
      label: 'FOOTER.CONTACT-US'
    },{
      url: '/our-mission',
      label: 'OUR-MISSION.TITLE'
    },{
      url: '/cookie-policy',
      label: 'FOOTER.COOKIE-POLICY'
    },{
      url: '/privacy',
      label: 'FOOTER.PRIVACY-NOTICE'
    },{
      url: '/terms-of-service',
      label: 'FOOTER.TERMS-OF-SERVICE'
    }]
  },{
    name: 'FOOTER.MEDIAS',
    links: [{
      href: 'https://www.facebook.com/worldluxuryhome/',
      label: 'Facebook',
      target: '_blank',
      rel: "noreferrer"
    },{
      href: 'https://www.instagram.com/worldluxuryhome/',
      label: 'Instagram',
      target: '_blank',
      rel: "noreferrer"
    },{
      href: 'https://twitter.com/worldluxuryhome ',
      label: 'Twitter',
      target: '_blank',
      rel: "noreferrer"
    },{
      href: 'https://www.youtube.com/channel/UCHr2of12AqWQj1i826Ftj1w ',
      label: 'YouTube',
      target: '_blank',
      rel: "noreferrer"
    },{
      href: 'https://www.linkedin.com/company/worldluxuryhome/',
      label: 'LinkedIn',
      target: '_blank',
      rel: "noreferrer"
    }]
  }];

  constructor(public i18NService: I18NService, private userService: UserService, private appSettings: AppSettings, private firebaseService: FirebaseService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.lang = this.i18NService.currentLanguage;
    // Get the list of currencies
    this.currencies = this.appSettings.CURRENCIES;
    // Watch language
    this.subs.push(this.i18NService.lang_obs.subscribe((lang) => {
      this.lang = lang;
    }, (err) => {
      console.log(err);
    }));
    // Watch displayed currency
    this.subs.push(this.userService.displayCurrency_obs.subscribe((currency) => {
      this.currency = currency;
    }, (err) => {
      console.log(err);
      this.currency = this.appSettings.DEFAULT_CURRENCY;
    }));
    // Get query params
    this.subs.push(this.route.queryParams.subscribe((params) => {
      // If no firestore allowed, do not load cities
      if (params && params.nofs)
        this.showCities = false;
      // Get cities
      if (this.showCities == true) {
        this.firebaseService.getCities(NB_CITIES_MAX).then((cities: any) => {
          this.countries = [];
          this.tempCountries = {};
          if (cities && cities.length > 0) {
            this.cities = cities.map((d) => {
              let res = d.data();
              return res;
            });
            this.cities = this.cities.sort((c1, c2) => (c1.country.toLowerCase() > c2.country.toLowerCase() ? 1 : -1));
            this.cities.forEach((c) => {
              this.tempCountries[c.country] = this.tempCountries[c.country] || [];
              this.tempCountries[c.country].push(c);
            });
            for (var countryName in this.tempCountries) {
              this.countries.push({
                name: countryName,
                cities: this.tempCountries[countryName]
              });
            }
          }
        }, (err) => {
          console.log(err);
        });
      }
    }));
  }

  languageChanged(event) {
    if (!event || !event.detail || !event.detail.value || event.detail.value == this.lang)
      return;
    this.i18NService.changeLanguage(event.detail.value);
  }

  ngOnDestroy() {
    // Clear all the subscriptions
    this.subs.forEach(function(sub) {
      if (sub && sub.unsubscribe)
        sub.unsubscribe();
    });
  }

  currencyChanged(event) {
    if (event && event.detail && event.detail.value)
      this.userService.setUserCurrency(event.detail.value);
  }

}
