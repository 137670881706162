import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';

import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class VerifGuard implements CanActivate {

	constructor(private userService: UserService, private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		let user = this.userService.getUser();
		if (user == undefined)
			return false;
		if (user != undefined && user.emailVerified != true) {
			this.router.navigate(['/email-verification']);
			return false;
		}
		if (user != undefined && (user.profile == undefined || user.profile.isPresented != true)) {
			this.router.navigate(['/profile/' + user.uid], { queryParams: { returnUrl: state.url, tab: 'edit' }});
			return false;
		}
		return true;
	}

}
