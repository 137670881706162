import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-city-card',
  templateUrl: 'city-card.component.html',
  styleUrls: ['city-card.component.scss'],
})
export class CityCardComponent {

  // The cities to display
  @Input() city;

  constructor() {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  getCityUrl() {
    if (this.city && this.city.id)
      return '/city/' + this.city.id;
  }

}
