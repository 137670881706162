<!-- Spinner -->
<div *ngIf="fetchingUser == true" class="ion-padding flexCentered">
	<ion-spinner name="crescent"></ion-spinner>
</div>

<!-- Admin menu -->
<ion-list *ngIf="showAdmin == true" class="ion-no-padding" lines="full">
  <ion-item button="true" (click)="closeMenu()" routerLink="/admin" routerDirection="root">
    <ion-icon name="key" slot="start"></ion-icon>
    <ion-label>{{'APP.ADMIN' | translate}}</ion-label>
  </ion-item>
  <ion-item button="true" (click)="closeMenu()" routerLink="/admin/cities" routerDirection="root">
    <ion-icon name="business-outline" slot="start"></ion-icon>
    <ion-label>{{'USER.CITIES' | translate}}</ion-label>
  </ion-item>
</ion-list>

<!-- Menu -->
<ion-list *ngIf="fetchingUser != true && showAdmin != true" class="ion-no-padding" lines="full">

  <!-- <ion-item button="true" (click)="closeMenu()" routerLink="/" routerDirection="root" *ngIf="isDesktop != true">
    <ion-icon name="home" slot="start"></ion-icon>
    <ion-label>{{'APP.HOME' | translate}}</ion-label>
  </ion-item> -->

  <ion-item button="true" (click)="showAdmin = true" *ngIf="user && user.profile && user.profile.admin == true">
    <ion-icon name="key" slot="start"></ion-icon>
    <ion-label>{{'APP.ADMIN' | translate}}</ion-label>
    <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
  </ion-item>

  <ion-item button="true" (click)="closeMenu()" routerLink="/pricing" routerDirection="root" *ngIf="isDesktop != true">
    <ion-icon name="cash-outline" slot="start"></ion-icon>
    <ion-label>{{'APP.PRICING' | translate}}</ion-label>
  </ion-item>

  <ion-item button="true" (click)="closeMenu()" routerLink="/why-choose-us" routerDirection="root" *ngIf="isDesktop != true && user == undefined">
    <ion-icon name="help-circle-outline" slot="start"></ion-icon>
    <ion-label>{{'FOOTER.WHY-CHOOSE-US' | translate}}</ion-label>
  </ion-item>

  <ion-item button="true" (click)="closeMenu()" routerLink="/property/search" routerDirection="root" *ngIf="isDesktop != true">
    <ion-icon name="search-outline" slot="start"></ion-icon>
    <ion-label>{{'APP.PROPERTY-SEARCH' | translate}}</ion-label>
  </ion-item>

  <ion-item button="true" (click)="closeMenu()" routerLink="/property/submit" routerDirection="root" *ngIf="isDesktop != true">
    <ion-icon name="add-circle" slot="start"></ion-icon>
    <ion-label>{{'APP.SUBMIT-PROPERTY' | translate}}</ion-label>
  </ion-item>

  <ion-item button="true" (click)="closeMenu()" routerLink="/property/favorites" routerDirection="root" *ngIf="user != undefined">
    <ion-icon name="heart" slot="start"></ion-icon>
    <ion-label>{{'APP.FAVORITES' | translate}}</ion-label>
  </ion-item>

  <ion-item button="true" (click)="closeMenu()" [routerLink]="'/profile/' + user.uid" routerDirection="root" [queryParams]="{tab: 'listings'}" *ngIf="user != undefined">
    <ion-icon name="list-outline" slot="start"></ion-icon>
    <ion-label>{{'APP.LISTING' | translate}}</ion-label>
  </ion-item>

  <ion-item button="true" (click)="closeMenu()" routerLink="/my-subscription" routerDirection="root" *ngIf="user != undefined">
    <ion-icon name="diamond-outline" slot="start"></ion-icon>
    <ion-label>{{'APP.MY-SUBSCRIPTION' | translate}}</ion-label>
  </ion-item>

  <ion-item *ngIf="user == undefined">
    <ion-icon name="earth-outline" slot="start"></ion-icon>
    <ion-select [value]="lang" (ionChange)="languageChanged($event)" interface="popover" aria-label="language">
      <ion-select-option *ngFor="let language of i18NService.languages" [value]="language.value">{{ language.label }}</ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item id="authIonItem" slot="bottom" *ngIf="user != undefined">

    <ion-row class="w-100">

      <ion-col size="8" class="p-0">

        <ion-item button="true" (click)="closeMenu()" [routerLink]="'/profile/' + user.uid" routerDirection="root">
          <ion-icon name="person-circle-outline" slot="start"></ion-icon>
          <ion-label>{{'APP.PROFILE' | translate}}</ion-label>
        </ion-item>

      </ion-col>
      
      <ion-col size="4" class="p-0">

        <ion-item class="p-0">
          <ion-select [value]="lang" (ionChange)="languageChanged($event)" interface="popover" aria-label="language">
            <ion-select-option *ngFor="let language of i18NService.languages" [value]="language.value">{{ language.value | uppercase }}</ion-select-option>
          </ion-select>
        </ion-item>
        
      </ion-col>

    </ion-row>

  </ion-item>



  <ion-item id="authIonItem" slot="bottom">

    <ion-row class="w-100">

      <ion-col size="8" class="p-0">

        <ion-item id="logoutButton" *ngIf="user != undefined" button="true" (click)="closeMenu(); logout()">
          <ion-icon name="log-out-outline" slot="start"></ion-icon>
          <ion-label>{{'AUTH.LOGOUT' | translate}}</ion-label>
        </ion-item>

        <ion-item id="loginButton" *ngIf="user == undefined" button="true" (click)="closeMenu()" routerLink="/login" routerDirection="root">
          <ion-icon name="log-in-outline" slot="start"></ion-icon>
          <ion-label>{{'AUTH.LOGIN' | translate}}</ion-label>
        </ion-item>

      </ion-col>
      
      <ion-col size="4" class="p-0">

        <ion-item class="p-0">
          <ion-toggle id="themeButton" name="theme" mode="ios" [(ngModel)]="darkTheme" (ionChange)="toggleTheme()"></ion-toggle>
        </ion-item>
        
      </ion-col>

    </ion-row>

  </ion-item>

</ion-list>
