<!-- Admin action menu -->
<ion-list class="ion-no-padding" lines="full" *ngIf="!openMarkAsMenu && !openTranslateMenu && !openLanguageMenu && !openHomepageMenu">

  <!-- Open mark as menu -->
  <ion-item button="true" (click)="openMarkAsMenu = true">
    <ion-label>Mark as</ion-label>
    <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
  </ion-item>

  <!-- Homepage menu -->
  <ion-item button="true" (click)="openHomepageMenu = true">
    <ion-label>Homepage</ion-label>
    <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
  </ion-item>

  <!-- Language menu -->
  <ion-item button="true" (click)="openLanguageMenu = true">
    <ion-label>Language</ion-label>
    <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
  </ion-item>

  <!-- Open translate menu -->
  <ion-item button="true" (click)="openTranslateMenu = true">
    <ion-label>Translate</ion-label>
    <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
  </ion-item>

  <!-- Delete button -->
  <ion-item button="true" (click)="closeMenu('Delete')" color="danger">
    <ion-label>Delete</ion-label>
  </ion-item>

</ion-list>

<!-- Mark as buttons -->
<ion-list class="ion-no-padding" lines="full" *ngIf="openMarkAsMenu">
  <ion-item button="true" (click)="closeMenu(visibility)" *ngFor="let visibility of visibilities" [disabled]="visibility == searchVisibility">
    <ion-label>{{visibility}}</ion-label>
  </ion-item>
</ion-list>


<!-- Homepage buttons -->
<ion-list class="ion-no-padding" lines="full" *ngIf="openHomepageMenu">
  <!-- ON button -->
  <ion-item button="true" (click)="closeMenu('Homepage_on')">
    <ion-label>ON</ion-label>
  </ion-item>
  <!-- OFF button -->
  <ion-item button="true" (click)="closeMenu('Homepage_off')">
    <ion-label>OFF</ion-label>
  </ion-item>
</ion-list>

<!-- Translate buttons -->
<ion-list class="ion-no-padding" lines="full" *ngIf="openTranslateMenu">
  <!-- Translate button -->
  <ion-item button="true" (click)="closeMenu('Translate')">
    <ion-label>Request translation(s)</ion-label>
  </ion-item>
  <!-- Cancel translate requests -->
  <ion-item button="true" (click)="closeMenu('Cancel_translate')">
    <ion-label>Cancel request(s)</ion-label>
  </ion-item>
</ion-list>

<!-- Language buttons -->
<ion-list class="ion-no-padding" lines="full" *ngIf="openLanguageMenu">
  <!-- Language button -->
  <ion-item button="true" (click)="closeMenu('Change_language')">
    <ion-label>Change language</ion-label>
  </ion-item>
</ion-list>