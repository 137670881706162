<ion-toolbar color="alwaysDark">
  <ion-grid fixed>

    <ion-row class="pt-5">

      <!-- Links -->
      <ion-col size="12" size-sm="6" size-md="6" size-lg="3" size-xl="3" class="d-flex flex-column p-3 pb-4 align-items-center align-items-sm-start"
                *ngFor="let cat of categories">
        <h4 class="mt-0 mb-3">{{cat.name | translate}}</h4>
        <div *ngFor="let link of cat.links" class="mb-3">
          <a *ngIf="link.url" class="customLink contrast" [routerLink]="link.url" routerDirection="root">{{link.label | translate}}</a>
          <a *ngIf="link.href" class="customLink contrast" [href]="link.href" [target]="link.target" [rel]="link.rel">{{link.label | translate}}</a>
        </div>
      </ion-col>

      <!-- Settings -->
      <ion-col size="12" size-sm="6" size-md="6" size-lg="3" size-xl="3" class="d-flex flex-column pt-3 pl-0 pr-3 pb-4 align-items-center align-items-sm-start">
        <h4 class="mt-0 mb-3 pl-3">{{'FOOTER.SETTINGS' | translate}}</h4>
        <ion-item lines="none" id="languageItem" color="alwaysDark">
          <ion-icon name="earth-outline" slot="start"></ion-icon>
          <ion-select [value]="lang" (ionChange)="languageChanged($event)" interface="popover" aria-label="language">
            <ion-select-option *ngFor="let language of i18NService.languages" [value]="language.value">{{ language.label }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item lines="none" id="languageItem" color="alwaysDark">
          <ion-icon name="cash-outline" slot="start"></ion-icon>
          <ion-select [(ngModel)]="currency" *ngIf="currencies != undefined" interface="popover" aria-label="language" (ionChange)="currencyChanged($event)">
            <ion-select-option *ngFor="let currency of currencies" [value]="currency.code">{{currency.code}}</ion-select-option>
          </ion-select>
      </ion-item>
      </ion-col>

      <!-- Cities -->
      <ul id="cities" class="w-100 p-2 pt-4 pb-3" *ngIf="countries.length > 0">
        <li *ngFor="let co of countries" class="p-2">
          <div>
            <span class="mt-0">{{co.name}}</span>
            <ul class="p-0">
              <li *ngFor="let ci of co.cities" class="customLink underlined tertiary" [routerLink]="'/city/' + ci.id">{{ci.city}}</li>
            </ul>
          </div>
        </li>
      </ul>

      <!-- Logo -->
      <ion-col size="12" class="d-flex flex-row p-0 align-items-center justify-content-center">
        <img src="assets/icon/logo_transparent.png" height="120" width="120" alt="logo_image">
        <ion-label class="ml-2 mt-2">© 2022 WorldLuxuryHome {{'GLOBAL.BY' | translate}} World Luxury LLC. {{'FOOTER.ALL-RIGHTS' | translate}}.</ion-label>
      </ion-col>

      <!-- Other logos -->
      <ion-col size="12" class="d-flex flex-row px-0 py-3 align-items-center justify-content-center">
        <a href="https://www.thegoodestate.com/" target="_blank" class="px-2">
          <img src="https://badges.kaufberater.io/badge/thegoodestate/site?type=png&year=2022&lang=en" height="75" />
        </a>
        <a href="https://stripe.com/" target="_blank" class="px-2">
          <img src="assets/images/secure-stripe-payment-logo.png" height="45" />
        </a>
      </ion-col>

    </ion-row>

  </ion-grid>
</ion-toolbar>