<!-- Header -->
<ion-header class="ion-no-border">
  <ion-toolbar mode="md">

    <!-- Title -->
    <ion-buttons slot="start" id="appTitle">
      <ion-button class="menuButton bordered ml-2" routerLink="/" routerDirection="root">
        <!-- Logo -->
        <img [src]="cssClass != 'dark' ? 'assets/icon/logo_transparent_black.png' : 'assets/icon/logo_transparent.png'"
             height="46" width="46" class="mr-2" alt="logo_image">
        <h2 class="d-none d-xl-block">World Luxury Home</h2>
      </ion-button>
    </ion-buttons>

    <!-- Menu buttons (desktop only) -->
    <ion-buttons id="desktopButtons" *ngIf="isDesktop == true" slot="end">
      <!-- <ion-button class="menuButton bordered" routerLink="/" routerDirection="root">{{'APP.HOME' | translate}}</ion-button> -->
      <ion-button class="menuButton bordered" routerLink="/pricing" routerDirection="root">{{'APP.PRICING' | translate}}</ion-button>
      <ion-button class="menuButton bordered" routerLink="/why-choose-us" routerDirection="root" *ngIf="fetchingUser != true && user == undefined">{{'FOOTER.WHY-CHOOSE-US' | translate}}</ion-button>
      <ion-button class="menuButton bordered" routerLink="/property/search" routerDirection="root">{{'APP.PROPERTY-SEARCH' | translate}}</ion-button>
      <ion-button class="menuButton bordered" routerLink="/contact-us" routerDirection="root" [queryParams]="{topic: 1}">{{'APP.CONTACT-SALES' | translate}}</ion-button>
      <ion-button class="menuButton" fill="solid" color="tertiary" routerLink="/property/submit" routerDirection="root">{{'APP.SUBMIT-PROPERTY' | translate}}</ion-button>
    </ion-buttons>

    <!-- Profile button -->
    <ion-buttons slot="end">
      <ion-button id="profileButton" class="menuButton bordered">
        <ion-thumbnail *ngIf="fetchingUser != true">
          <profile-picture *ngIf="user == undefined" imageUrl="assets/icon/profile-grey.svg" size="32"></profile-picture>
          <profile-picture *ngIf="user != undefined && user.profile == undefined" size="32"></profile-picture>
          <profile-picture *ngIf="user != undefined && user.profile != undefined" [imageUrl]="user.profile.profile_picture" size="32"></profile-picture>
        </ion-thumbnail>
        <!-- Spinner -->
        <div *ngIf="fetchingUser == true">
          <ion-spinner name="crescent"></ion-spinner>
        </div>
        <!-- Menu icon -->
        <ion-icon id="menuIcon" name="menu-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-popover id="profileMenuPopover" trigger="profileButton" arrow="false">
      <ng-template>
        <app-profile-menu></app-profile-menu>
      </ng-template>
    </ion-popover>

  </ion-toolbar>
</ion-header>