import { Injectable } from "@angular/core";
import { Platform } from '@ionic/angular';
import { Observable, ReplaySubject } from 'rxjs';
import { createUserWithEmailAndPassword,
         GoogleAuthProvider,
         onAuthStateChanged,
         // signInWithCredential,
         signInWithEmailAndPassword,
         signInWithPopup,
         signOut,
         sendPasswordResetEmail
} from "firebase/auth";
import { onSnapshot, where } from "firebase/firestore";

import { AppSettings } from '../app/app.settings';

import { FirebaseService } from "./firebase.service";
import { LocalstorageService } from "./localstorage.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // Boolean if we are connecting
  private isConnecting: Boolean = true;
  public isConnecting_obs = new ReplaySubject<Boolean>(1);

  // User
  private user: any;
  public user_obs = new ReplaySubject<any>(1);

  // Auth object
  auth;

  // Profile subscription
  private profileSub;

  // List of currencies
  private currencies;
  private currencyLocalstorageKey = 'displayedCurrency';

  // List of measurement units
  private measurementUnits;

  // Display currency
  public displayCurrency_obs = new ReplaySubject<String>(1);

  firstTime = true;

  constructor(private appSettings: AppSettings, private firebaseService: FirebaseService, public platform: Platform, private localStorage: LocalstorageService) {

    this.displayCurrency_obs.next(this.appSettings.DEFAULT_CURRENCY);

    if (this.localStorage.getItem(this.currencyLocalstorageKey))
      this.displayCurrency_obs.next(this.localStorage.getItem(this.currencyLocalstorageKey));

    this.auth = this.firebaseService.getAuth();


    // For mobile only
    // if (Capacitor && Capacitor.getPlatform() != 'web') {
    //   // Init
    //   GoogleAuth.initialize();
    // }

    
    // That's the auto connect process
    /*
    onAuthStateChanged(this.auth, user => {
      if (this.firstTime == false)
        return;
      this.firstTime = false;
      // User connected
      // Update the database
      this._setUserInDatabase(user, undefined).then((u) => {
        this._setUser(u);
      }, (err) => {
        this._setUser(user);
      }).finally(() => {
        this._setIsConnecting(false);
      });
    }, (err) => {
      this.firstTime = false;
      this._setIsConnecting(false);
    });
    */
    this.user_obs.next(this.user);

    // Get the list of currencies
    this.currencies = this.appSettings.CURRENCIES;
    // Get the list of measurement units
    this.measurementUnits = this.appSettings.MEASUREMENT_UNITS;
  }










  // -------------------------------------------------------
  // Public getters functions
  // -------------------------------------------------------
  public async isLoggedIn() {
    return this.user != undefined;
  }
  public getUserUid() {
    if (this.user == undefined)
      return undefined;
    return this.user.uid;
  }
  public getUser() {
    return this.user;
  }
  // Returns true if the UID passed in parameter is equal to the current connected user
  public amIThisUser(uid) {
    const currentUid = this.getUserUid();
    if (currentUid == undefined || uid == undefined || currentUid.toLowerCase == undefined ||
        uid.toLowerCase == undefined || currentUid.toLowerCase() != uid.toLowerCase())
      return false;
    return true;
  }
  // Returns true or false if the user is admin
  public isAdmin() {
    return (this.user && this.user.profile && this.user.profile.admin == true) ? true : false;
  }











  // -------------------------------------------------------
  // Public functions
  // -------------------------------------------------------
  // Load a profile defined by the UID passed as parameter
  public getProfile(uid) {
  	return new Observable((obs) => {
      onSnapshot(this.firebaseService.getUserRef(uid), (userResult: any) => {
        if (!userResult || userResult.exists() == false) {
          obs.next(undefined);
          return;
        }
        let user = userResult.data();
        if (user == undefined) {
          obs.next(undefined);
          return;
        }
        user.id = uid;
        // Encode the address for the Maps link
        if (user.address && user.address.length > 0)
          user.encoded_address = encodeURIComponent(user.address);
        // Look for eventual agencies
        let ref = this.firebaseService.getUserRef(user.id);
        this.firebaseService.getUsers(where('agents', 'array-contains', ref)).then((agencies) => {
          if (agencies && agencies.size > 0 && !agencies.empty)
            user.agencies = agencies.docs;
          obs.next(user);
        }, (err) => {
          console.log(err);
          obs.next(user);
        });
      });
  	});
  }
  // Update the user profile data in our database
  public saveProfileDatabase(user) {
    return new Promise((resolve, reject) => {
      if (user == undefined || user.email == undefined)
        return reject('MISSING_USER_INFOS');
      // Get current time
      var now = new Date().getTime();
      user.last_login_at = now;
      // Check if user is already in database
      this.firebaseService.getUsers(where('email', '==', user.email)).then((users) => {
        if (!users || users.empty == true || users.size <= 0)
          return reject('CANNOT_FIND_USER');
        let u = users.docs[0];
        this.firebaseService.updateUser(u.id, user).then(() => {
          // Update our internal variable
          this.user.profile = {...this.user.profile, ...user};
          // Notify observables
          this.user_obs.next(this.user);
          this.updateDisplayCurrency(this.user);
          resolve(undefined);
        }, (err) => {
          console.log(err);
          reject('CANNOT_ACCESS_DATABASE');
        });
      }, (err) => {
        console.log(err);
        return reject('CANNOT_FIND_USER');
      });
    });
  }














  // -------------------------------------------------------
  // Other public functions
  // -------------------------------------------------------
  // Send a reinitialization password email to this user
  public reinitializePassword(email) {
    if (!this.auth)
      return;
    return sendPasswordResetEmail(this.auth, email);
  }

  // Set the properties for this user, and update the database
  public setUserProperties(properties) {
    if (this.user == undefined || this.user.profile == undefined || properties == undefined)
      return;
    // Update user's properties in database
    this.firebaseService.getUsers(where('email', '==', this.user.email)).then((users) => {
      if (users && users.empty != true && users.size > 0) {
        this.firebaseService.updateUser(users.docs[0].id, properties).then(() => {
          // Update user
          for (let key in properties)
            this.user.profile[key] = properties[key];
          // Notify observables
          this.user_obs.next(this.user);
          this.updateDisplayCurrency(this.user);
        });
      }
    });
  }

  // Set user currency
  public setUserCurrency(currencyCode) {
    if (currencyCode == undefined)
      return;
    // Find currency
    var newCurrency = this.getCurrencyFromCode(currencyCode);
    if (newCurrency == undefined)
      return;
    // If user is not logged in
    if (this.user == undefined || this.user.profile == undefined) {
      this.updateDisplayCurrency(undefined, currencyCode);
      return;
    }
    // Update user's properties in database
    this.firebaseService.getUsers(where('email', '==', this.user.email)).then((users) => {
      if (users && users.empty != true && users.size > 0) {
        this.firebaseService.updateUser(users.docs[0].id, {
          preferred_currency: newCurrency
        }).then(() => {
          // Update user
          this.user.profile.preferred_currency = newCurrency;
          // Notify observables
          this.user_obs.next(this.user);
          this.updateDisplayCurrency(this.user);
        });
      }
    });
  }

  // Add/Remove the property from favorite list
  // Return true/false if the property has been added/removed
  public toggleFavoriteProp(propId) {
    return new Promise((resolve, reject) => {
      if (this.user == undefined || this.user.profile == undefined)
        return reject();
      // Get the list of favorite properties
      var favoriteProperties = this.user.profile.favorite_properties || [];
      // Check if property already favorite
      var propIsFavorite = favoriteProperties.indexOf(propId) != -1;
      // Add/remove the property from the list
      if (propIsFavorite == false)
        favoriteProperties.push(propId);
      else
        favoriteProperties = favoriteProperties.filter((p) => p != propId);
      // Update user's list of favorite properties in database
      this.firebaseService.getUsers(where('email', '==', this.user.email)).then((users) => {
        if (users && users.empty != true && users.size > 0) {
          this.firebaseService.updateUser(users.docs[0].id, { favorite_properties: favoriteProperties }).then(() => {
            // Update user
            this.user.profile.favorite_properties = favoriteProperties;
            return resolve({ propId: propId, isFavorite: !propIsFavorite });
          }, (err) => {
            console.log(err);
            return reject();
          });
        }
      }, (err) => {
        console.log(err);
        return reject();
      });
    });
  }

  // Get an ID token for server verification
  public getIdToken() {
    if (this.user && this.user.getIdToken)
      return this.user.getIdToken();
    return;
  }















  // -------------------------------------------------------
  // Auth public functions
  // -------------------------------------------------------
  // Logout the user
  public logout() {
    return this._logout();
  }
  // Email & password registration
  public registerUser(value) {
    this._setIsConnecting(true);
    return this._registerUser(value);
  }
  // Email & password authentication
  public loginUser(value) {
    this._setIsConnecting(true);
    return this._loginUser(value);
  }
  // Google authentication
  public loginWithGoogle() {
    this._setIsConnecting(true);
    return this._loginWithGoogle();
  }












  // -------------------------------------------------------
  // Auth private functions
  // -------------------------------------------------------
  // Logout the user
  private _logout() {
    return new Promise<any>((resolve, reject) => {
      if (this.auth) {
        signOut(this.auth).then(() => {
          this._setUser(undefined);
          resolve(undefined);
        }, (err) => {
          this._setUser(undefined);
          reject(err);
        }).catch((err) => {
          this._setUser(undefined);
          reject(err);
        });
      } else {
        this._setUser(undefined);
        resolve(undefined);
      }
    });
  }
  // Email & password registration
  private _registerUser(value) {
    return new Promise<any>((resolve, reject) => {
      try {
        createUserWithEmailAndPassword(this.auth, value.email, value.password).then((res) => {
          if (res == undefined || res.user == undefined) {
            this._setIsConnecting(false);
            return reject();
          }
          // Create user in database if needed
          this._setUserInDatabase(res.user, value).then((u) => {
            this._setUser(u);
            this._setIsConnecting(false);
            resolve(this.user);
          }, (err) => {
            this._setUser(res.user);
            this._setIsConnecting(false);
            resolve(this.user);
          });
        }, (err) => {
          this._setIsConnecting(false);
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  // Email & password authentication
  private _loginUser(value) {
    return new Promise<any>((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, value.email, value.password).then(
        res => {
          if (res == undefined || res.user == undefined) {
            this._setIsConnecting(false);
            return reject();
          }
          this._setUserInDatabase(res.user, undefined).then((u) => {
            this._setUser(u);
            this._setIsConnecting(false);
            resolve(this.user);
          }, (err) => {
            this._setUser(res.user);
            this._setIsConnecting(false);
            resolve(this.user);
          });
        },
        err => {
          this._setIsConnecting(false);
          reject(err);
        }
      )
    });
  }
  // Google authentication
  private _loginWithGoogle() {
    return new Promise<any>((resolve, reject) => {
      const provider = new GoogleAuthProvider();

      // Browser auth
      // if (Capacitor && Capacitor.getPlatform() == 'web') {
        signInWithPopup(this.auth, provider).then((res) => {
          if (res == undefined || res.user == undefined) {
            this._setIsConnecting(false);
            return reject();
          }
          this._setUserInDatabase(res.user, undefined).then((u) => {
            this._setUser(u);
            this._setIsConnecting(false);
            resolve(this.user);
          }, (err) => {
            this._setUser(res.user);
            this._setIsConnecting(false);
            resolve(this.user);
          });
        }, (err) => {
          console.log(err);
          this._setIsConnecting(false);
          reject(err);
        }).catch((err) => {
          console.log(err);
          this._setIsConnecting(false);
          reject(err);
        });
      
      // }

      // Mobile auth
      /*
      else {

        GoogleAuth.signIn().then((res1: any) => {
          var credential = GoogleAuthProvider.credential(res1.authentication.idToken);
          signInWithCredential(this.auth, credential).then((res) => {
            if (res && res.user) {
              this._setUser(res.user);
              this._setIsConnecting(false);
              resolve(res.user);
            } else {
              this._setIsConnecting(false);
              reject();
            }
          }, (err) => {
            this._setIsConnecting(false);
            reject();
          });
        }, (err) => {
          console.log(err);
          this._setIsConnecting(false);
          reject(err);
        });
        
      }
      */
    });
  }














  // -------------------------------------------------------
  // Private functions
  // -------------------------------------------------------
  private _setIsConnecting(value) {
    this.isConnecting = value;
    this.isConnecting_obs.next(this.isConnecting);
  }

  private async _setUser(user) {
    // If we are connecting
    if (user != undefined && user.uid != undefined) {
      // Get profile
      this.profileSub = this.getProfile(user.uid).subscribe(async (profile: any) => {
        profile = profile || {};
        // Unsubscribe if we can
        if (this.profileSub != undefined && this.profileSub.unsubscribe)
          this.profileSub.unsubscribe();
        this.user = user;
        // Set the profile
        this.user.profile = profile;
        // Notify observables
        this.user_obs.next(this.user);
        this.updateDisplayCurrency(this.user);
      });
    }
    // Else, we are deconnecting
    else {
      // Notify observables
      this.user = undefined;
      this.user_obs.next(this.user);
      this.updateDisplayCurrency(this.user);
    }
  }

  private updateDisplayCurrency(user, newCurrencyCode = undefined) {
    if (user && user.profile && user.profile.preferred_currency && user.profile.preferred_currency.code) {
      this.displayCurrency_obs.next(user.profile.preferred_currency.code);
      this.localStorage.setItem(this.currencyLocalstorageKey, user.profile.preferred_currency.code);
    }
    else if (newCurrencyCode != undefined) {
      this.displayCurrency_obs.next(newCurrencyCode);
      this.localStorage.setItem(this.currencyLocalstorageKey, newCurrencyCode);
    }
    else if (this.localStorage.getItem(this.currencyLocalstorageKey))
      this.displayCurrency_obs.next(this.localStorage.getItem(this.currencyLocalstorageKey));
    else
      this.displayCurrency_obs.next(this.appSettings.DEFAULT_CURRENCY);
  }

  private getCurrencyFromCode(currencyCode) {
    if (!currencyCode || currencyCode.length <= 0)
      return;
    // Find currency
    var newCurrency = this.currencies.filter((c) => c.code.toLowerCase() == currencyCode.toLowerCase());
    if (newCurrency.length <= 0)
      return;
    return newCurrency[0];
  }

  private _setUserInDatabase(user, value) {
    return new Promise((resolve, reject) => {
      if (user != undefined && user.email != undefined)
        reject({internalError: 'MISSING_USER_INFOS'});
      // Get current time
      var now = new Date().getTime();
      // Check if user is already in database
      this.firebaseService.getUsers(where('email', '==', user.email)).then((users) => {
        // If user already created, just update the last login timestamp
        if (users && users.empty != true && users.size > 0) {
          this.firebaseService.updateUser(users.docs[0].id, { last_login_at: now }).then(() => {
            resolve(user);
          }, (err) => {
            console.log(err);
            reject({internalError: 'CANNOT_ACCESS_DATABASE'});
          });
        }
        // Else, create a new user
        else {
          // If there is a displayName, use it, else use the email address
          var displayName = (value != undefined && value.displayName != undefined && value.displayName.length > 0) ? value.displayName : user.email;
          displayName = (user.displayName != undefined) ? user.displayName : displayName;
          // Find default currency
          var defaultCurrency = this.currencies.filter((c) => c.code.toLowerCase() == this.appSettings.DEFAULT_CURRENCY.toLowerCase())[0];
          // Find default measurement unit
          var foundMeasurementUnits = this.measurementUnits.filter((c) => c && c.value && c.value.toLowerCase() == this.appSettings.DEFAULT_MEASUREMENT_UNIT.toLowerCase());
          var defaultMeasurementUnit = (foundMeasurementUnits && foundMeasurementUnits.length > 0) ? foundMeasurementUnits[0] : this.appSettings.DEFAULT_MEASUREMENT_UNIT.toLowerCase();
          // Create a new user in database
          var newUser = {
            uid: user.uid,
            displayName: displayName,
            created_at: now,
            last_login_at: now,
            email: user.email,
            type: 'agent',
            preferred_currency: defaultCurrency,
            measurement_unit: defaultMeasurementUnit
          };
          this.firebaseService.createUser(newUser.uid, newUser).then(() => {
            resolve(newUser);
          }, (err) => {
            console.log(err);
            reject({internalError: 'CANNOT_ACCESS_DATABASE'});
          });
        }
      }, (err) => {
        console.log(err);
        reject({internalError: 'CANNOT_ACCESS_DATABASE'});
      });
    });
  }

}
