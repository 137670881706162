import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'joinWith',
    pure: true
})
export class JoinWithPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value && value.filter && value.length > 1) {
            const newValues = value.filter((v) => v && v.length > 0 && v.trim).map((v) => v.trim());
            if (newValues.length > 0) {
                if (args)
                    return newValues.join(' ' + args + ' ');
                else
                    return newValues.join(' ');
            }
            return '';
        }
        return '';
    }

}