// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	name: 'world-luxury-home',
	serverUrl: "https://test.worldluxuryhome.com/",
	domain: 'world-luxury-home-424cf.firebaseapp.com',
	firebase: {
		apiKey: "AIzaSyDGScupGLWs6p7YXTozNi4qh4Btu5PZmWU",
		authDomain: "world-luxury-home-424cf.firebaseapp.com",
		databaseURL: "https://world-luxury-home-424cf-default-rtdb.europe-west1.firebasedatabase.app",
		projectId: "world-luxury-home-424cf",
		storageBucket: "world-luxury-home-424cf.appspot.com",
		messagingSenderId: "657596925812",
		appId: "1:657596925812:web:e0382aebf7cad142610e21",
		measurementId: "G-TDXKNRVX5C"
	},
	stripe: {
		apiKey: 'pk_test_51Kly3CBjHu1UazyuMwDwrhQplqyIgVIOlSC1rqxjUH5hITQc1myAJnNYsOI3yBOGd6NhuMd5FlvgOxfplsMH6Q2800XVf4itF2'
	},
	geoapify: {
		apiKey: 'a87dfc6011ad48fc89078cadcbbd0f23'
	},
	recaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
	// websiteUrl: 'http://localhost:4200/'
	// websiteUrl: 'https://world-luxury-home-424cf.firebaseapp.com/'
	websiteUrl: 'https://test.worldluxuryhome.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
