import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { take } from "rxjs/operators";

import { LocalstorageService } from "./localstorage.service";

@Injectable({
  providedIn: 'root'
})
export class I18NService {

    public languages = [
        { value: 'en', label: 'English' },
        { value: 'fr', label: 'Français' },
        { value: 'it', label: 'Italiano' },
        { value: 'es', label: 'Español' }
    ]
    public currentLanguage = 'en';

    // Language
    public lang_obs = new ReplaySubject<any>(1);

    public showSelect = true;

    languageLocalStorageKey = 'lang';

    isBrowser;

    constructor(private translateService: TranslateService, @Inject(PLATFORM_ID) platformId: Object, private localStorage: LocalstorageService) {
        this.isBrowser = isPlatformBrowser(platformId);
        if (this.isBrowser) {
            // Add languages and set default
            var langs = this.languages.map((l) => l.value);
            translateService.addLangs(langs);
            translateService.setDefaultLang(this.currentLanguage);
            this.lang_obs.next(this.currentLanguage);
            this._updateSelects();
            // Load user's language
            this._loadLanguage();
        }
    }

    // Return true if found the language, else return false
    public changeLanguage(languageValue) {
        if (languageValue && this._langValueExists(languageValue)) {
            this.currentLanguage = languageValue;
            this.translateService.use(this.currentLanguage);
            this.lang_obs.next(this.currentLanguage);
            this._updateSelects();
            if (this.localStorage.setItem)
                this.localStorage.setItem(this.languageLocalStorageKey, this.currentLanguage);
            return true;
        }
        return false;
    }

    private _loadLanguage() {
        // Check local storage
        if (this.localStorage.getItem) {
            if (this.changeLanguage(this.localStorage.getItem(this.languageLocalStorageKey)) == true)
                return;
        }
        // Check browser language
        const browserLang = this.translateService.getBrowserLang();
        if (this._langValueExists(browserLang)) {
            this.translateService.use(browserLang);
            this.lang_obs.next(browserLang);
            this._updateSelects();
        }
        else {
            this.translateService.use(this.currentLanguage);
            this.lang_obs.next(this.currentLanguage);
            this._updateSelects();
        }
    }

    private _langValueExists(value) {
        var found = this.languages.filter((l) => l.value == value);
        return (found && found.length > 0);
    }

    public getMetas() {
        return new Promise((resolve) => {
            this.translateService.get(['METAS']).pipe(take(1)).subscribe((metas) => {
                resolve(metas['METAS']);
            });
        });
    }

    private _updateSelects() {
        this.showSelect = false;
        if (this.isBrowser) {
            setTimeout(() => {
                this.showSelect = true;
            }, 10);
        }
    }
}