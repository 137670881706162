import { Injectable } from "@angular/core";
import { getFirestore, collection, query, getDocs, getDoc, doc, updateDoc, deleteDoc, addDoc, setDoc, where } from "firebase/firestore";
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

import { environment } from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

    // Db
    private db = getFirestore(initializeApp(environment.firebase, environment.name));

    // Users collection
    private userCollectionName = 'users';
    private userCollection;
    // XML collection
    private xmlCollectionName = 'xmls';
    private xmlCollection;
    // XML Mapping collection
    private xmlMappingCollectionName = 'xml_mappings';
    private xmlMappingCollection;
    // Customer collection
    private CustomerCollectionName = 'customers';
    // CheckoutSession collection
    private CheckoutSessionCollectionName = 'checkout_sessions';
    // Subscription collection
    private SubscriptionCollectionName = 'subscriptions';
    // Payment collection
    private PaymentCollectionName = 'payments';
    // Cities collection
    private citiesCollectionName = 'cities';
    private citiesCollection;
    // Cities_by_name collection
    private citiesNameCollectionName = 'cities_by_name';
    private citiesNameCollection;
    // Buffer for the cities names
    private citiesNames;

    // Firebase
    private firebaseApp;
    private auth;
    public functions;
    public storage;
    public analytics;

    constructor() {
        this.firebaseApp = initializeApp(environment.firebase, environment.name);
        // this.analytics = getAnalytics(this.firebaseApp);
        // if (Capacitor.isNativePlatform())
        //     this.auth = initializeAuth(this.firebaseApp, {
        //         persistence: indexedDBLocalPersistence
        //     });
        // else
            this.auth = getAuth(this.firebaseApp);
        this.auth.useDeviceLanguage();
        this.functions = getFunctions(this.firebaseApp);
        this.storage = getStorage(this.firebaseApp);
        this.userCollection = collection(this.db, this.userCollectionName);
        this.xmlCollection = collection(this.db, this.xmlCollectionName);
        this.xmlMappingCollection = collection(this.db, this.xmlMappingCollectionName);
        this.citiesCollection = collection(this.db, this.citiesCollectionName);
        this.citiesNameCollection = collection(this.db, this.citiesNameCollectionName);
    }

    public getAuth() {
        return this.auth;
    }


    // -------------------------------------------------------
    // Users functions
    // -------------------------------------------------------
    // Returns the properties for this query
    public getUsers(q = null, q2 = null, q3 = null) {
        return Promise.resolve({docs: [], empty: true, size: 0});
        if (q == null)
            return getDocs(this.userCollection);
        if (q2 == null)
            return getDocs(query(this.userCollection, q));
        if (q3 == null)
            return getDocs(query(this.userCollection, q, q2));
        return getDocs(query(this.userCollection, q, q2, q3));
    }
    // Return a reference to this user
    public getUserRef(uid) {
        return undefined;
        return doc(this.db, this.userCollectionName, uid);
    }
    // Update a user
    public updateUser(uid, changes) {
        return Promise.reject();
        return updateDoc(doc(this.db, this.userCollectionName, uid), changes);
    }
    // Create a new user
    public createUser(uid, data) {
        return Promise.reject();
        return setDoc(doc(this.db, this.userCollectionName, uid), data);
    }
    


    // -------------------------------------------------------
    // XML functions
    // -------------------------------------------------------
    // Returns the XMLS for this query
    public getXmls(q = null) {
        return Promise.resolve(undefined);
        if (q == null)
            return getDocs(this.xmlCollection);
        return getDocs(query(this.xmlCollection, q));
    }
    // Return the XML defined by this ID
    public getXml(xmlId) {
        return Promise.resolve(undefined);
        return getDoc(doc(this.db, this.xmlCollectionName, xmlId));
    }
    // Create and returns a new XML document
    public createXml(data) {
        return Promise.reject();
        return addDoc(collection(this.db, this.xmlCollectionName), data);
    }
    // Update a XML document
    public updateXml(xmlId, changes) {
        return Promise.resolve();
        return updateDoc(doc(this.db, this.xmlCollectionName, xmlId), changes);
    }
    // Delete a XML document
    public deleteXml(xmlId) {
        return Promise.resolve();
        return deleteDoc(doc(this.db, this.xmlCollectionName, xmlId));
    }



    // -------------------------------------------------------
    // Products / Subscriptions functions
    // -------------------------------------------------------
    // Add a checkout session to this customer
    public addCheckoutSession(uid, data) {
        return Promise.reject();
        return addDoc(collection(this.db, this.CustomerCollectionName, uid, this.CheckoutSessionCollectionName), data);
    }
    // Return the subscriptions for this customer
    public getSubscriptions(uid, q = null) {
        return Promise.resolve(undefined);
        if (q == null)
            return getDocs(collection(this.db, this.CustomerCollectionName, uid, this.SubscriptionCollectionName));
        return getDocs(query(collection(this.db, this.CustomerCollectionName, uid, this.SubscriptionCollectionName), q));
    }



    // -------------------------------------------------------
    // Cities functions
    // -------------------------------------------------------
    // Returns the city with this name
    public getCity(name) {
        return Promise.resolve(undefined);
        return getDocs(query(this.citiesCollection, where('id', '==', name)));
    }
    // Return the first X cities (only with display fields)
    public getCities(nb = 0) {
        return Promise.resolve(undefined);
        return new Promise((resolve, reject) => {
            if (this.citiesNames) {
                return (nb == 0) ? resolve(this.citiesNames) : resolve(this.shuffle(this.citiesNames).slice(0, nb));
            }
            getDocs(query(this.citiesNameCollection)).then((res) => {
                this.citiesNames = res.docs;
                return (nb == 0) ? resolve(this.citiesNames) : resolve(this.shuffle(this.citiesNames).slice(0, nb));
            }, (err) => {
                return reject(err);
            });
        });
    }
    // Return all the cities with all their content
    public getFullCities() {
        return Promise.resolve(undefined);
        return getDocs(query(this.citiesCollection));
    }
    // Add a new city
    public createCity(data) {
        return Promise.reject();
        return addDoc(collection(this.db, this.citiesCollectionName), data);
    }
    // Update a city
    public updateCity(id, changes) {
        return Promise.reject();
        return updateDoc(doc(this.db, this.citiesCollectionName, id), changes);
    }
    // Delete a city
    public deleteCity(id) {
        return Promise.reject();
        return deleteDoc(doc(this.db, this.citiesCollectionName, id));
    }



    // -------------------------------------------------------
    // Private functions
    // -------------------------------------------------------
    shuffle(array) {
        let currentIndex = array.length,  randomIndex;
        // While there remain elements to shuffle.
        while (currentIndex != 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
        return array;
    }

}