<!-- Pagination -->
<ion-row class="slideHandles d-flex justify-content-end py-3" *ngIf="swiperHasPages">
	<ion-button color="tertiary" (click)="slidePrev()" [disabled]="isSwiperBeginning">
	  <ion-icon name="chevron-back-outline"></ion-icon>
	</ion-button>
	<ion-button color="tertiary" (click)="slideNext()" [disabled]="isSwiperEnd">
	  <ion-icon name="chevron-forward-outline"></ion-icon>
	</ion-button>
  </ion-row>

<!-- Latest users -->
<ion-row class="userCarouselComp" *ngIf="users && users.length > 0">
	<ion-col size="12" class="p-0">
		<swiper #swiper [pagination]="true" [config]="slideOpts" class="full-width" (slidesGridLengthChange)="onBreakpoint($event)" (slideChange)="onSlideChange()" (breakpoint)="onBreakpoint($event)">
			<ng-template swiperSlide *ngFor="let user of users">
				<div class="userDiv d-flex flex-column justify-content-center align-items-center w-100"
					[routerLink]="'/profile/' + user.uid" routerDirection="forward" style="outline: none">
					<profile-picture *ngIf="user != undefined && user.profile == undefined" size="100"></profile-picture>
					<profile-picture *ngIf="user != undefined && user.profile != undefined" [imageUrl]="user.profile.profile_picture" size="100"></profile-picture>
					<span class="text-truncate w-100" *ngIf="user && user.displayName != undefined">
						{{user.displayName}}
					</span>
				</div>
			</ng-template>
		</swiper>
	</ion-col>
</ion-row>