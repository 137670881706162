import { Component, HostBinding, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { isPlatformBrowser } from '@angular/common';

import { UserService } from '../../../services/user.service';
import { I18NService } from '../../../services/i18n.service';
import { ThemeService } from '../../../services/theme.service';
import { WindowService } from '../../../services/window.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent {

  // Current user
  user;
  fetchingUser = true;

  // Subscriptions
  subs = [];

  isDesktop;
  isBrowser;

  @HostBinding('class') public cssClass: string;

  constructor(public i18NService: I18NService, private userService: UserService,
              private zone: NgZone, private themeService: ThemeService, @Inject(PLATFORM_ID) platformId: Object,
              private menu: MenuController, private windowService: WindowService) {
    // Watch isDesktop
    this.subs.push(this.windowService.isDesktop.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    }));
    this.isBrowser = isPlatformBrowser(platformId);
    // Watch user
    this.subs.push(this.userService.user_obs.subscribe((user) => {
      this.zone.run(() => {
        this.user = user;
        this.fetchingUser = false;
      });
    }, (err) => {
      this.fetchingUser = false;
    }));
  }

  ngOnInit() {
    // Watch for the theme
    this.subs.push(this.themeService.theme.subscribe((dark: boolean) => {
      if (dark)
        this.cssClass = 'dark';
      else
        this.cssClass = '';
    }));
  }

  ngOnDestroy() {
    // Clear all the subscriptions
    this.subs.forEach(function(sub) {
      if (sub && sub.unsubscribe)
        sub.unsubscribe();
    });
  }

  closeMenu() {
    this.menu.close('end');
  }

}
