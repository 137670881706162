import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WindowService {

  public isDesktop = new BehaviorSubject<Boolean>(true);
  public WIDTH_FOR_DESKTOP = 768;

  constructor(@Inject(DOCUMENT) private doc: Document) {}

  getWindow(): Window | null {
    return this.doc.defaultView;
  }

  getLocation(): Location {
    return this.doc.location;
  }

  createElement(tag: string): HTMLElement {
    return this.doc.createElement(tag);
  }

  updateWindowSize(newWidth: number) : void {
    this.isDesktop.next(newWidth && newWidth >= this.WIDTH_FOR_DESKTOP);
  }
}