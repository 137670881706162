import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'admin-action-menu',
  templateUrl: 'admin-action-menu.component.html',
  styleUrls: ['admin-action-menu.component.scss'],
})
export class AdminActionMenuComponent implements OnInit, OnDestroy {

  // The current visibility of the listed properties
  @Input() searchVisibility;

  // Mark as buttons
  visibilities = ['Published', 'Draft', 'Sold', 'Not published'];
  // Boolean if the mark as menu is open
  openMarkAsMenu: Boolean = false;
  openTranslateMenu: Boolean = false;
  openLanguageMenu: Boolean = false;
  openHomepageMenu: Boolean = false;

  constructor(private popoverController: PopoverController) {}

  ngOnInit() {}

  ngOnDestroy() {}

  closeMenu(action: string = undefined) {
  	if (this.popoverController != undefined && this.popoverController.dismiss != undefined)
  		this.popoverController.dismiss({
        action: action
      });
  }

}
