import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class AppSettings {

  // WhatsApp phone number
  WHATSAPP_PHONE_NUMBER = '17866711079';
  WHATSAPP_PHONE_NUMBER_STR = '+1 786 671 1079';           // Used only to display nicely

  HQ_ADDRESS = '440 N Barranca Ave #3386, Covina, CA 91723';

  // Maximum file size for the images
  MAX_UPLOAD_IMAGE_SIZE = 4000000;

	// Map zoom level when no location is provided
	DEFAULT_MAP_ZOOM = 10;
  // Map zoom level when a location is provided
  DEFAULT_MAP_LOCATION_ZOOM = 13;

  // The minimum price for a property
  MIN_PRICE = 500000;
  // The maximum price for the search price range (property price can be higher)
  MAX_PRICE = 10000000;

  // Measurement units
  MEASUREMENT_UNITS = ["m²", "ft²"];
  // Default measurement unit
  DEFAULT_MEASUREMENT_UNIT = this.MEASUREMENT_UNITS[0];

  // List of property types
  PROPERTY_TYPES = ["Apartment", "House", "Studio", "Villa", "Bungalow", "Business", "Castle", "Commercial", "Farm", "Farmhouse",
                    "Finca", "Hotel", "Land lot", "Office", "Other", "Penthouse", "Town house", "Warehouse", "Condo", "Family home",
                    "Entire building", "Project", "Shop"];
  // List of property types that can have a floor number
  PROPERTY_TYPES_WITH_FLOOR_NB = ["Apartment", "Studio", "Bungalow", "Business", "Commercial", "Hotel", "Office"];
  // Default property types (for the searches)
  DEFAULT_SEARCH_TYPES = [this.PROPERTY_TYPES[0], this.PROPERTY_TYPES[1], this.PROPERTY_TYPES[2], this.PROPERTY_TYPES[3]];

  // List of conditions
  CONDITIONS = ["New", "Excellent", "Good", "Quite good", "Needs renovation", "To be constructed"];

  // List of property status
  PROPERTY_STATUS = ["For sale", "For rent", "Commercial for sale", "Commercial for rent", "Vacation rental", "Sold", "Rented"];
  // Default property status (for the searches)
  DEFAULT_SEARCH_STATUS = [this.PROPERTY_STATUS[0], this.PROPERTY_STATUS[1], this.PROPERTY_STATUS[2], this.PROPERTY_STATUS[3], this.PROPERTY_STATUS[4]];
  // Default property status (for the submit property)
  DEFAULT_PROPERTY_STATUS = this.PROPERTY_STATUS[0];

  // Geoapify: time (in ms) between each request
  GEOAPIFY_DELAY_BETWEEN_REQUEST = 500;

  // List of property features
  PROPERTY_FEATURES = ["Attic", "Swimming Pool", "Jacuzzi", "Backyard", "Frontyard", "Garden", "Heating", "Air conditioning", "Ocean view",
                       "Lake view", "City view", "Wine cellar", "Basketball court", "Gym", "Fireplace", "Sprinklers", "Deck", "Balcony",
                       "Terrace", "Laundry", "Concierge", "Doorman", "Storage", "Recreation", "Roof deck", "Private beach", "Security",
                       "Elevator", "Alarm system", "Lounge", "Game room", "Wooden floor", "Excellent investment", "Walking distance to center/shops",
                       "Close to schools", "Pieces of arts", "Historical building", "Furnished", "Solar panels"];
  // The matching percentage used to consider a string being "identic" to a feature
  PROPERTY_FEATURES_SIMILARITY_PERCENTAGE = 0.8;

  // List of property energy classes
  PROPERTY_ENERGY_CLASSES = [{"class": "A+++", "color": "#00964e", "textColor": "white", "horizontalPadding": "10px"},
                            {"class": "A++", "color": "#00aa01", "textColor": "white", "horizontalPadding": "20px"},
                            {"class": "A+", "color": "#a9c801", "textColor": "white", "horizontalPadding": "30px"},
                            {"class": "A", "color": "#ffff4d", "textColor": "black", "horizontalPadding": "40px"},
                            {"class": "B", "color": "#fecc45", "textColor": "white", "horizontalPadding": "50px"},
                            {"class": "C", "color": "#ff7d01", "textColor": "white", "horizontalPadding": "60px"},
                            {"class": "D", "color": "#fe0000", "textColor": "white", "horizontalPadding": "70px"},
                            {"class": "E", "color": "#e10000", "textColor": "white", "horizontalPadding": "80px"},
                            {"class": "F", "color": "#c80000", "textColor": "white", "horizontalPadding": "90px"},
                            {"class": "G", "color": "#ae0001", "textColor": "white", "horizontalPadding": "100px"}];

  // List of currencies
  CURRENCIES = [{"label": "U.S. dollar", "code": "USD"},
                {"label": "Euro", "code": "EUR"},
                {"label": "Emirati dirham", "code": "AED"},
                {"label": "Australian dollar", "code": "AUD"},
                {"label": "Canadian dollar", "code": "CAD"},
                {"label": "Yen", "code": "JPY"},
                {"label": "Brazilian real", "code": "BRL"},
                {"label": "New Zealand dollar", "code": "NZD"},
                {"label": "Pound sterling", "code": "GBP"},
                {"label": "Thai baht", "code": "THB"}];
  // Default currency
  DEFAULT_CURRENCY = this.CURRENCIES[0].code;

  // List of available topics for the "contact-us" page
  CONTACT_TOPICS = ['Customer support', 'Business request', 'Partnership', 'Media and press', 'Work with us', 'Other'];

  // List of available languages
  TRANSLATE_LANGUAGES_LABELS = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
    { value: 'fr', label: 'Français' },
    { value: 'it', label: 'Italiano' },
    { value: 'pt', label: 'Português' },
    { value: 'de', label: 'Deutsch' }
  ];
  TRANSLATE_LANGUAGES = this.TRANSLATE_LANGUAGES_LABELS.map((l) => l.value).sort();

  // List of countries
  COUNTRIES = [{"name": "United States", "code": "US"},
              {"name": "France", "code": "FR"},
              {"name": "Spain", "code": "ES"}, 
              {"name": "Italy", "code": "IT"}, 
              {"name": "Afghanistan", "code": "AF"}, 
              {"name": "Aland Islands", "code": "AX"}, 
              {"name": "Albania", "code": "AL"}, 
              {"name": "Algeria", "code": "DZ"}, 
              {"name": "American Samoa", "code": "AS"}, 
              {"name": "Andorra", "code": "AD"}, 
              {"name": "Angola", "code": "AO"}, 
              {"name": "Anguilla", "code": "AI"}, 
              {"name": "Antarctica", "code": "AQ"}, 
              {"name": "Antigua and Barbuda", "code": "AG"}, 
              {"name": "Argentina", "code": "AR"}, 
              {"name": "Armenia", "code": "AM"}, 
              {"name": "Aruba", "code": "AW"}, 
              {"name": "Australia", "code": "AU"}, 
              {"name": "Austria", "code": "AT"}, 
              {"name": "Azerbaijan", "code": "AZ"}, 
              {"name": "Bahamas", "code": "BS"}, 
              {"name": "Bahrain", "code": "BH"}, 
              {"name": "Bangladesh", "code": "BD"}, 
              {"name": "Barbados", "code": "BB"}, 
              {"name": "Belarus", "code": "BY"}, 
              {"name": "Belgium", "code": "BE"}, 
              {"name": "Belize", "code": "BZ"}, 
              {"name": "Benin", "code": "BJ"}, 
              {"name": "Bermuda", "code": "BM"}, 
              {"name": "Bhutan", "code": "BT"}, 
              {"name": "Bolivia", "code": "BO"}, 
              {"name": "Bosnia and Herzegovina", "code": "BA"}, 
              {"name": "Botswana", "code": "BW"}, 
              {"name": "Bouvet Island", "code": "BV"}, 
              {"name": "Brazil", "code": "BR"}, 
              {"name": "British Indian Ocean Territory", "code": "IO"}, 
              {"name": "Brunei Darussalam", "code": "BN"}, 
              {"name": "Bulgaria", "code": "BG"}, 
              {"name": "Burkina Faso", "code": "BF"}, 
              {"name": "Burundi", "code": "BI"}, 
              {"name": "Cambodia", "code": "KH"}, 
              {"name": "Cameroon", "code": "CM"}, 
              {"name": "Canada", "code": "CA"}, 
              {"name": "Cape Verde", "code": "CV"}, 
              {"name": "Cayman Islands", "code": "KY"}, 
              {"name": "Central African Republic", "code": "CF"}, 
              {"name": "Chad", "code": "TD"}, 
              {"name": "Chile", "code": "CL"}, 
              {"name": "China", "code": "CN"}, 
              {"name": "Christmas Island", "code": "CX"}, 
              {"name": "Cocos (Keeling) Islands", "code": "CC"}, 
              {"name": "Colombia", "code": "CO"}, 
              {"name": "Comoros", "code": "KM"}, 
              {"name": "Congo", "code": "CG"}, 
              {"name": "Congo", "code": "CD"}, 
              {"name": "Cook Islands", "code": "CK"}, 
              {"name": "Costa Rica", "code": "CR"}, 
              {"name": "Cote D Ivoire", "code": "CI"}, 
              {"name": "Croatia", "code": "HR"}, 
              {"name": "Cuba", "code": "CU"}, 
              {"name": "Cyprus", "code": "CY"}, 
              {"name": "Czech Republic", "code": "CZ"}, 
              {"name": "Denmark", "code": "DK"}, 
              {"name": "Djibouti", "code": "DJ"}, 
              {"name": "Dominica", "code": "DM"}, 
              {"name": "Dominican Republic", "code": "DO"}, 
              {"name": "Ecuador", "code": "EC"}, 
              {"name": "Egypt", "code": "EG"}, 
              {"name": "El Salvador", "code": "SV"}, 
              {"name": "Equatorial Guinea", "code": "GQ"}, 
              {"name": "Eritrea", "code": "ER"}, 
              {"name": "Estonia", "code": "EE"}, 
              {"name": "Ethiopia", "code": "ET"}, 
              {"name": "Falkland Islands (Malvinas)", "code": "FK"}, 
              {"name": "Faroe Islands", "code": "FO"}, 
              {"name": "Fiji", "code": "FJ"}, 
              {"name": "Finland", "code": "FI"}, 
              {"name": "France", "code": "FR"},
              {"name": "French Guiana", "code": "GF"}, 
              {"name": "French Polynesia", "code": "PF"}, 
              {"name": "French Southern Territories", "code": "TF"}, 
              {"name": "Gabon", "code": "GA"}, 
              {"name": "Gambia", "code": "GM"}, 
              {"name": "Georgia", "code": "GE"}, 
              {"name": "Germany", "code": "DE"}, 
              {"name": "Ghana", "code": "GH"}, 
              {"name": "Gibraltar", "code": "GI"}, 
              {"name": "Greece", "code": "GR"}, 
              {"name": "Greenland", "code": "GL"}, 
              {"name": "Grenada", "code": "GD"}, 
              {"name": "Guadeloupe", "code": "GP"}, 
              {"name": "Guam", "code": "GU"}, 
              {"name": "Guatemala", "code": "GT"}, 
              {"name": "Guernsey", "code": "GG"}, 
              {"name": "Guinea", "code": "GN"}, 
              {"name": "Guinea-Bissau", "code": "GW"}, 
              {"name": "Guyana", "code": "GY"}, 
              {"name": "Haiti", "code": "HT"}, 
              {"name": "Heard Island and Mcdonald Islands", "code": "HM"}, 
              {"name": "Holy See (Vatican City State)", "code": "VA"}, 
              {"name": "Honduras", "code": "HN"}, 
              {"name": "Hong Kong", "code": "HK"}, 
              {"name": "Hungary", "code": "HU"}, 
              {"name": "Iceland", "code": "IS"}, 
              {"name": "India", "code": "IN"}, 
              {"name": "Indonesia", "code": "ID"}, 
              {"name": "Iran", "code": "IR"}, 
              {"name": "Iraq", "code": "IQ"}, 
              {"name": "Ireland", "code": "IE"}, 
              {"name": "Isle of Man", "code": "IM"}, 
              {"name": "Israel", "code": "IL"}, 
              {"name": "Italy", "code": "IT"}, 
              {"name": "Jamaica", "code": "JM"}, 
              {"name": "Japan", "code": "JP"}, 
              {"name": "Jersey", "code": "JE"}, 
              {"name": "Jordan", "code": "JO"}, 
              {"name": "Kazakhstan", "code": "KZ"}, 
              {"name": "Kenya", "code": "KE"}, 
              {"name": "Kiribati", "code": "KI"}, 
              {"name": "Kuwait", "code": "KW"}, 
              {"name": "Kyrgyzstan", "code": "KG"}, 
              {"name": "Lao People S Democratic Republic", "code": "LA"}, 
              {"name": "Latvia", "code": "LV"}, 
              {"name": "Lebanon", "code": "LB"}, 
              {"name": "Lesotho", "code": "LS"}, 
              {"name": "Liberia", "code": "LR"}, 
              {"name": "Libyan Arab Jamahiriya", "code": "LY"}, 
              {"name": "Liechtenstein", "code": "LI"}, 
              {"name": "Lithuania", "code": "LT"}, 
              {"name": "Luxembourg", "code": "LU"}, 
              {"name": "Macao", "code": "MO"}, 
              {"name": "Macedonia", "code": "MK"}, 
              {"name": "Madagascar", "code": "MG"}, 
              {"name": "Malawi", "code": "MW"}, 
              {"name": "Malaysia", "code": "MY"}, 
              {"name": "Maldives", "code": "MV"}, 
              {"name": "Mali", "code": "ML"}, 
              {"name": "Malta", "code": "MT"}, 
              {"name": "Marshall Islands", "code": "MH"}, 
              {"name": "Martinique", "code": "MQ"}, 
              {"name": "Mauritania", "code": "MR"}, 
              {"name": "Mauritius", "code": "MU"}, 
              {"name": "Mayotte", "code": "YT"}, 
              {"name": "Mexico", "code": "MX"}, 
              {"name": "Micronesia", "code": "FM"}, 
              {"name": "Moldova", "code": "MD"}, 
              {"name": "Monaco", "code": "MC"}, 
              {"name": "Mongolia", "code": "MN"}, 
              {"name": "Montenegro", "code": "ME"},
              {"name": "Montserrat", "code": "MS"}, 
              {"name": "Morocco", "code": "MA"}, 
              {"name": "Mozambique", "code": "MZ"}, 
              {"name": "Myanmar", "code": "MM"}, 
              {"name": "Namibia", "code": "NA"}, 
              {"name": "Nauru", "code": "NR"}, 
              {"name": "Nepal", "code": "NP"}, 
              {"name": "Netherlands", "code": "NL"}, 
              {"name": "Netherlands Antilles", "code": "AN"}, 
              {"name": "New Caledonia", "code": "NC"}, 
              {"name": "New Zealand", "code": "NZ"}, 
              {"name": "Nicaragua", "code": "NI"}, 
              {"name": "Niger", "code": "NE"}, 
              {"name": "Nigeria", "code": "NG"}, 
              {"name": "Niue", "code": "NU"}, 
              {"name": "Norfolk Island", "code": "NF"}, 
              {"name": "North Korea", "code": "KP"}, 
              {"name": "Northern Mariana Islands", "code": "MP"}, 
              {"name": "Norway", "code": "NO"}, 
              {"name": "Oman", "code": "OM"}, 
              {"name": "Pakistan", "code": "PK"}, 
              {"name": "Palau", "code": "PW"}, 
              {"name": "Palestinian Territory Occupied", "code": "PS"}, 
              {"name": "Panama", "code": "PA"}, 
              {"name": "Papua New Guinea", "code": "PG"}, 
              {"name": "Paraguay", "code": "PY"}, 
              {"name": "Peru", "code": "PE"}, 
              {"name": "Philippines", "code": "PH"}, 
              {"name": "Pitcairn", "code": "PN"}, 
              {"name": "Poland", "code": "PL"}, 
              {"name": "Portugal", "code": "PT"}, 
              {"name": "Puerto Rico", "code": "PR"}, 
              {"name": "Qatar", "code": "QA"}, 
              {"name": "Reunion", "code": "RE"}, 
              {"name": "Romania", "code": "RO"}, 
              {"name": "Russian Federation", "code": "RU"}, 
              {"name": "Rwanda", "code": "RW"}, 
              {"name": "Saint Helena", "code": "SH"}, 
              {"name": "Saint Kitts and Nevis", "code": "KN"}, 
              {"name": "Saint Lucia", "code": "LC"}, 
              {"name": "Saint Pierre and Miquelon", "code": "PM"}, 
              {"name": "Saint Vincent and the Grenadines", "code": "VC"}, 
              {"name": "Samoa", "code": "WS"}, 
              {"name": "San Marino", "code": "SM"}, 
              {"name": "Sao Tome and Principe", "code": "ST"}, 
              {"name": "Saudi Arabia", "code": "SA"}, 
              {"name": "Senegal", "code": "SN"}, 
              {"name": "Serbia", "code": "RS"}, 
              {"name": "Seychelles", "code": "SC"}, 
              {"name": "Sierra Leone", "code": "SL"}, 
              {"name": "Singapore", "code": "SG"}, 
              {"name": "Slovakia", "code": "SK"}, 
              {"name": "Slovenia", "code": "SI"}, 
              {"name": "Solomon Islands", "code": "SB"}, 
              {"name": "Somalia", "code": "SO"}, 
              {"name": "South Africa", "code": "ZA"}, 
              {"name": "South Georgia and the South Sandwich Islands", "code": "GS"}, 
              {"name": "South Korea", "code": "KR"}, 
              {"name": "Spain", "code": "ES"}, 
              {"name": "Sri Lanka", "code": "LK"}, 
              {"name": "Sudan", "code": "SD"}, 
              {"name": "Suriname", "code": "SR"}, 
              {"name": "Svalbard and Jan Mayen", "code": "SJ"}, 
              {"name": "Swaziland", "code": "SZ"}, 
              {"name": "Sweden", "code": "SE"}, 
              {"name": "Switzerland", "code": "CH"}, 
              {"name": "Syrian Arab Republic", "code": "SY"}, 
              {"name": "Taiwan Province of China", "code": "TW"}, 
              {"name": "Tajikistan", "code": "TJ"}, 
              {"name": "Tanzania", "code": "TZ"}, 
              {"name": "Thailand", "code": "TH"}, 
              {"name": "Timor-Leste", "code": "TL"}, 
              {"name": "Togo", "code": "TG"}, 
              {"name": "Tokelau", "code": "TK"}, 
              {"name": "Tonga", "code": "TO"}, 
              {"name": "Trinidad and Tobago", "code": "TT"}, 
              {"name": "Tunisia", "code": "TN"}, 
              {"name": "Turkey", "code": "TR"}, 
              {"name": "Turkmenistan", "code": "TM"}, 
              {"name": "Turks and Caicos Islands", "code": "TC"}, 
              {"name": "Tuvalu", "code": "TV"}, 
              {"name": "Uganda", "code": "UG"}, 
              {"name": "Ukraine", "code": "UA"}, 
              {"name": "United Arab Emirates", "code": "AE"}, 
              {"name": "United Kingdom", "code": "GB"}, 
              {"name": "United States", "code": "US"},
              {"name": "United States Minor Outlying Islands", "code": "UM"}, 
              {"name": "Uruguay", "code": "UY"}, 
              {"name": "Uzbekistan", "code": "UZ"}, 
              {"name": "Vanuatu", "code": "VU"}, 
              {"name": "Venezuela", "code": "VE"}, 
              {"name": "Viet Nam", "code": "VN"}, 
              {"name": "Virgin Islands British", "code": "VG"}, 
              {"name": "Virgin Islands U.S.", "code": "VI"}, 
              {"name": "Wallis and Futuna", "code": "WF"}, 
              {"name": "Western Sahara", "code": "EH"}, 
              {"name": "Yemen", "code": "YE"}, 
              {"name": "Zambia", "code": "ZM"}, 
              {"name": "Zimbabwe", "code": "ZW"}];

	constructor() {}

  // -------------------------------------------------------
  // Public functions
  // -------------------------------------------------------
  // Return the energy class that matches the class passed in parameter
  // Return undefined if energy class not found
  public getEnergyClass(energyClass) {
    if (energyClass == undefined || energyClass.toLowerCase == undefined)
      return;
    var result = this.PROPERTY_ENERGY_CLASSES.filter((ec) => ec.class.toLowerCase() == energyClass.toLowerCase());
    if (result && result.length > 0)
      return result[0];
    return;
  }

  // Return the country name that matches the country code passed in parameter
  public getCountryName(countryCode) {
    if (!countryCode || !countryCode.toUpperCase)
      return countryCode;
    var countryName = countryCode;
    for (var i = 0; i < this.COUNTRIES.length; i++) {
      if (this.COUNTRIES[i].code == countryCode.toUpperCase() || this.COUNTRIES[i].name == countryCode.toUpperCase()) {
        countryName = this.COUNTRIES[i].name;
        break;
      }
    }
    return countryName;
  }

}
