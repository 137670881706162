import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-testimonial-card',
  templateUrl: 'testimonial-card.component.html',
  styleUrls: ['testimonial-card.component.scss'],
})
export class TestimonialCardComponent {

  // The testimonials to display
  @Input() testimonial;

  // Boolean if we want shadow on the card
  @Input() shadow: Boolean = false;
  
  constructor() {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

}
