<!-- Testimonial card -->
<ion-card style="outline: none" class="m-0 p-3 h-100 text-left" [ngClass]="{'shadow-sm': shadow}">
  <div class="d-flex flex-row align-items-center mb-3">
    <profile-picture size="32" style="height: 32px"></profile-picture>
    <div class="ml-2">
      <ion-card-title *ngIf="testimonial.name">{{testimonial.name}}</ion-card-title>
      <ion-card-subtitle *ngIf="testimonial.location">{{testimonial.location}}</ion-card-subtitle>
    </div>
  </div>
  <ion-label *ngIf="testimonial.review" [innerHtml]="testimonial.review || ''" class="pt-2"></ion-label>
</ion-card>