import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { FileSizePipe } from './file-size.pipe';
import { DateAgoPipe } from './time-ago.pipe';
import { PricePipe } from './price.pipe';
import { JoinWithPipe } from './join-with.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
  	FileSizePipe,
  	DateAgoPipe,
    PricePipe,
    JoinWithPipe
  ],
  exports: [
  	FileSizePipe,
  	DateAgoPipe,
    PricePipe,
    JoinWithPipe
  ],
  providers: [ CurrencyPipe ]
})
export class PipesModule { }