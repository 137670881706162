<!-- Property card -->
<ion-card [routerLink]="getPropertyUrl(property)" routerDirection="forward" style="outline: none" class="m-0" [ngClass]="{'shadow-sm': shadow}">
  <div class="img" *ngIf="property && property.images && property.images.length > 0">
    <img style="display:none;" loading="lazy" [src]="property.images[0].url_low" (error)="showErrImageParent($event.target)" alt="property_image" class="w-100 h-100" style="object-fit: cover">
  </div>
  <div class="img" *ngIf="!property || !property.images || property.images.length <= 0"
    [ngStyle]="{'background': 'url(\'assets/images/not-found.jpg\')'}" >
  </div>
  <ion-card-content class="pt-3 text-truncate d-flex flex-column">

    <ion-card-title style="white-space: nowrap;" class="text-left">
      <ion-label class="m-0 fw-bold propPrice" *ngIf="property.price != 0">{{ property.price | price:property.currency | async }}</ion-label>
      <ion-label class="m-0 fw-bold propPrice" *ngIf="property.price == 0">{{ 'PROP.PRICE' | translate }}: {{ 'PROP.UPON-REQUEST' | translate }}</ion-label>
    </ion-card-title>

    <h3 class="text-truncate text-left mt-2" [innerHTML]="property.title | titlecase | safe:'html'"></h3>

    <ion-label color="medium" class="text-truncate text-left mt-1" [innerHtml]="property.address"></ion-label>

    <ion-row class="propertyQuickInfos d-flex justify-content-around align-items-center pt-3 lighter">

      <span *ngIf="propHasBedrooms(property)" class="d-flex justify-content-center align-items-center">
        <ion-icon name="bed-outline" class="mr-1"></ion-icon>
        {{property.nb_bedrooms}}
      </span>

      <span *ngIf="propHasBathrooms(property)" class="d-flex justify-content-center align-items-center">
        <span class="material-icons-outlined">shower</span>
        {{property.nb_bathrooms}}
      </span>

      <span *ngIf="propHasSurface(property)" class="d-flex justify-content-center align-items-center">
        <span class="material-icons-outlined">square_foot</span>
        {{property.size_in}} {{property.measurement_unit}}
      </span>

    </ion-row>

    <a [href]="getPropertyUrl(property)"></a>

  </ion-card-content>
</ion-card>