<ion-grid>

	<!-- Properties -->
	<ion-row *ngIf="properties && properties.length > 0">
		<ion-col size="12" size-sm="6" size-md="6" size-lg="4" size-xl="3" *ngFor="let property of properties">
			<app-property-card [property]="property"></app-property-card>
		</ion-col>
	</ion-row>

	<!-- Pagination -->
	<ion-row class="d-flex justify-content-center my-4">
		<app-pagination #pagination
			[nbPropertiesPerPage]="nbPropertiesPerPage"
			[fetchingProperties]="fetchingProperties"
			(newPageChanged)="searchPage($event)"
			(alreadyViewedPageChanged)="loadSavedPage($event)"
			(indexChanged)="indexChanged($event)"
		>
		</app-pagination>
	</ion-row>

</ion-grid>